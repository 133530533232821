// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { TFunction } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Field, Label, Textarea } from '@headlessui/react';

// Components, Layouts, Pages
import {
  BaseButton,
  BaseDatePicker,
  BaseInput,
  BaseSelect,
  Loading,
  Modal,
  ModalUnderDevelopment,
  RadioAddSchedule,
  RateSelect,
  SelectTime,
} from '~/components';
// Context

// Others
import { IFormSchedule, IScheduleTask } from '~/utils/interface/schedule';
import { ButtonTypeEnum, CRMEnum, RadioCrmEnum, TypeUserEnum, TimeFormatEnum } from '~/utils/enum';
import { IBaseOption, IListDataResponse, IListQueryParams } from '~/utils/interface/common';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { RootState } from '~/redux/store';
import {
  ASTERISK_SYMBOL,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_INPUT_HEIGHT,
  DEFAULT_LIMIT_MAX_ITEM,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { optionsType } from '~/mockData';
import { IListClient, IListQueryParamsClients } from '~/utils/interface/crm/clients';
import { getListClients } from '~/thunks/crm/clients/clientsThunk';
import { getListCaregiver } from '~/thunks/caregiver/caregiverThunk';
import { ICaregiver } from '~/mockData/mockInterface';
import { IListQueryPayroll } from '~/utils/interface/rate';
import { getListPayroll } from '~/thunks/rate/rateThunk';
import { getUserName } from '~/utils/helper';
import { createShiftSchedule } from '~/thunks/schedule/scheduleThunk';
import { rateActions } from '~/thunks/rate/rateSlice';

// Styles, images, icons
import styles from './FormShiftScheduleModal.module.scss';
import { icons } from '~/assets';

type Props = {
  isOpen: boolean;
  onClose?: () => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup.object({
    type: yup.string().trim().required(t('common_required_field')),
    clientId: yup.string().trim().required(t('common_required_field')),
    caregiverId: yup.string().trim().required(t('common_required_field')),
    startDate: yup.string().trim().required(t('common_required_field')),
    endDate: yup.string().trim().required(t('common_required_field')),
    startTime: yup.string().trim().required(t('common_required_field')),
    endTime: yup.string().trim().required(t('common_required_field')),
    clientRateId: yup.string().trim().required(t('common_required_field')),
    caregiverRateId: yup.string().trim().required(t('common_required_field')),
    timeZone: yup.string().trim().optional(),
    tasks: yup
      .array()
      .of(
        yup.object({
          name: yup.string().required(t('common_required_field')),
          detail: yup.string().optional(),
          startTime: yup.string().optional(),
        })
      )
      .optional(),
  });
};

const defaultValues: IFormSchedule = {
  clientId: EMPTY_STRING,
  caregiverId: EMPTY_STRING,
  startDate: EMPTY_STRING,
  endDate: EMPTY_STRING,
  startTime: EMPTY_STRING,
  endTime: EMPTY_STRING,
  type: optionsType[0].value.toString(),
  clientRateId: EMPTY_STRING,
  caregiverRateId: EMPTY_STRING,
  tasks: [{ name: EMPTY_STRING, detail: EMPTY_STRING, startTime: EMPTY_STRING }],
};

const FormShiftScheduleModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors, isDirty },
  } = useForm<IFormSchedule>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValues,
  });

  const {
    fields: tasksFields,
    append: appendEquipment,
    remove: removeEquipment,
  } = useFieldArray({
    control,
    name: 'tasks',
  });
  //#endregion Declare Hook

  //#region Selector
  const { listCaregiverRate, listClientRate, isFetchingPayrollCaregiver, isFetchingPayrollClient } = useAppSelector(
    (state: RootState) => state.rateState
  );
  //#endregion Selector

  //#region Declare State
  const [selectedClientRate, setSelectedClientRate] = useState<string>(RadioCrmEnum.HOURLY);
  const [selectedCaregiverRate, setSelectedCaregiverRate] = useState<string>(RadioCrmEnum.HOURLY);
  const [inputHeight, setInputHeight] = useState<number | null>(null);
  const [isShowUnderDevelopment, setIsShowUnderDevelopment] = useState<boolean>(false);
  const [optionClient, setOptionClient] = useState<IBaseOption[]>();
  const [optionCaregiver, setOptionCaregiver] = useState<IBaseOption[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListClients();
    handleGetCaregiver();
  }, []);

  useEffect(() => {
    const clientId = getValues('clientId');
    if (!clientId) return;

    handleGetListClientRate(clientId);
  }, [selectedClientRate, watch('clientId')]);

  useEffect(() => {
    const caregiverId = getValues('caregiverId');
    if (!caregiverId) return;

    handleGetListCaregiverRate(caregiverId);
  }, [selectedCaregiverRate, watch('caregiverId')]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListClients = () => {
    const params: IListQueryParamsClients = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
      type: [CRMEnum.CLIENT],
    };

    dispatch(getListClients(params))
      .unwrap()
      .then((res) => {
        const { responses, pagination }: IListDataResponse<IListClient[]> = res?.data;

        const listClient = responses.map((data) => {
          return { label: getUserName(data.firstName, data.middleName, data.lastName), value: data.id };
        });

        setOptionClient(listClient);
      })
      .catch((error) => {});
  };

  const handleGetCaregiver = () => {
    const params: IListQueryParams = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
    };

    dispatch(getListCaregiver(params))
      .unwrap()
      .then((res) => {
        const { responses, pagination }: IListDataResponse<ICaregiver[]> = res?.data;

        const listCaregiver = responses.map((data) => {
          return { label: getUserName(data.firstName, data.middleName, data.lastName), value: data.id };
        });

        setOptionCaregiver(listCaregiver);
      })
      .catch((err) => {});
  };

  const handleGetListClientRate = (clientId: string) => {
    dispatch(rateActions.setFetchingPayrollRates({ userType: TypeUserEnum.CLIENT }));

    const params: IListQueryPayroll = {
      page: DEFAULT_NUMBER_ONE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
      userId: clientId,
      rateType: selectedClientRate,
      userType: TypeUserEnum.CLIENT,
    };

    dispatch(getListPayroll(params))
      .unwrap()
      .then((res) => {
        const data = res.data.data;
        if (!data?.responses?.[DEFAULT_NUMBER_ZERO]) return;

        setValue('clientRateId', data.responses?.[DEFAULT_NUMBER_ZERO].id);
      })
      .catch((err) => {});
  };

  const handleGetListCaregiverRate = (caregiverId: string) => {
    dispatch(rateActions.setFetchingPayrollRates({ userType: TypeUserEnum.CAREGIVER }));

    const params: IListQueryPayroll = {
      page: DEFAULT_NUMBER_ONE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
      userId: caregiverId,
      rateType: selectedCaregiverRate,
      userType: TypeUserEnum.CAREGIVER,
    };

    dispatch(getListPayroll(params))
      .unwrap()
      .then((res) => {
        const data = res.data.data;
        if (!data?.responses?.[DEFAULT_NUMBER_ZERO]) return;

        setValue('caregiverRateId', data.responses?.[DEFAULT_NUMBER_ZERO].id);
      })
      .catch((err) => {});
  };

  const handleClose = () => {
    dispatch(rateActions.resetPayroll());
    onClose?.();
  };

  const handleAddTask = () => {
    appendEquipment({
      name: EMPTY_STRING,
      startTime: EMPTY_STRING,
      detail: EMPTY_STRING,
    });
  };

  const handleRemoveTask = (index: number) => {
    removeEquipment(index);
  };

  const handleSubmitSchedule = (data: IFormSchedule) => {
    setIsLoading(true);

    const newData: IFormSchedule = {
      ...data,
      tasks: data.tasks?.map((task) => {
        const filteredTask: Partial<IScheduleTask> = {};
        Object.entries(task).forEach(([key, value]) => {
          if (
            (value !== null && value !== undefined && value !== EMPTY_STRING && !Array.isArray(value)) ||
            (Array.isArray(value) && value.length > DEFAULT_NUMBER_ZERO)
          ) {
            filteredTask[key as keyof typeof task] = value;
          }
        });

        return filteredTask as IScheduleTask;
      }),
    };

    const filteredData: Partial<IFormSchedule> = {};

    Object.entries(newData).forEach(([key, value]) => {
      if (
        (value !== null && value !== undefined && value !== EMPTY_STRING && !Array.isArray(value)) ||
        (Array.isArray(value) && value?.length > DEFAULT_NUMBER_ZERO)
      ) {
        filteredData[key as keyof IFormSchedule] = value;
      }
    });

    dispatch(createShiftSchedule(filteredData as IFormSchedule))
      .unwrap()
      .then((_res) => {
        handleClose();
      })
      .catch((_error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClientRate = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedClientRate(e.target.value);
    setValue('clientRateId', EMPTY_STRING);
  };

  const handleCaregiverRate = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedCaregiverRate(e.target.value);
    setValue('caregiverRateId', EMPTY_STRING);
  };

  const handleSetHeightInputDetail = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value) {
      setInputHeight(e.target.scrollHeight);
    } else {
      setInputHeight(null);
    }
  };

  const handleCloseDevelopment = () => {
    setIsShowUnderDevelopment(!isShowUnderDevelopment);
  };
  //#endregion Handle Function

  return (
    <Modal isOpen={isOpen} onClose={handleClose} title={t('form_shift_schedule_modal_add_schedule_tile')}>
      <form id='addScheduleModalComponent' className={cx('container')} onSubmit={handleSubmit(handleSubmitSchedule)}>
        <div className={cx('contentModal')}>
          <div className={cx('twoCol')}>
            <Controller
              name='type'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseSelect
                  options={optionsType || []}
                  value={value || EMPTY_STRING}
                  label={t('form_shift_schedule_modal_type_label')}
                  placeholder={t('common_select_placeholder')}
                  onChange={({ value }) => {
                    onChange(value);
                  }}
                  errorMessage={errors.type?.message}
                  required
                />
              )}
            />
            <Controller
              name='clientId'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseSelect
                  value={value}
                  options={optionClient || []}
                  label={t('form_shift_schedule_modal_client_label')}
                  placeholder={t('common_select_placeholder')}
                  onChange={({ value }) => {
                    onChange(value);
                  }}
                  errorMessage={errors.clientId?.message}
                  required
                />
              )}
            />
          </div>

          <div className={cx('twoCol')}>
            <Controller
              name='startDate'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseDatePicker
                  label={t('form_shift_schedule_modal_start_date_label')}
                  onDateSelected={onChange}
                  value={value}
                  name={'startDate'}
                  placeholderText={t('common_placeholder_select')}
                  errorMessage={errors.startDate?.message}
                  required
                />
              )}
            />

            <Controller
              name='startTime'
              control={control}
              render={({ field: { value, onChange } }) => (
                <SelectTime
                  name={'startTime'}
                  label={t('form_shift_schedule_modal_start_time_label')}
                  placeholder={t('common_placeholder_select')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                  errorMessage={errors.startTime?.message}
                  required
                />
              )}
            />
          </div>

          <div className={cx('twoCol')}>
            <Controller
              name='endDate'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseDatePicker
                  label={t('form_shift_schedule_modal_end_date_label')}
                  onDateSelected={onChange}
                  value={value}
                  name={'endDate'}
                  placeholderText={t('common_placeholder_select')}
                  required
                  errorMessage={errors.endDate?.message}
                />
              )}
            />

            <Controller
              name='endTime'
              control={control}
              render={({ field: { value, onChange } }) => (
                <SelectTime
                  name={'endTime'}
                  label={t('form_shift_schedule_modal_end_time_label')}
                  placeholder={t('common_placeholder_select')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                  errorMessage={errors.endTime?.message}
                  required
                />
              )}
            />
          </div>

          <div className={cx('twoCol')}>
            <Controller
              name='caregiverId'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseSelect
                  value={value}
                  options={optionCaregiver || []}
                  label={t('form_shift_schedule_modal_caregiver_label')}
                  placeholder={t('common_select_placeholder')}
                  onChange={({ value }) => {
                    onChange(value);
                  }}
                  errorMessage={errors.caregiverId?.message}
                  required
                />
              )}
            />
          </div>

          <div className={cx('wrapRadio')}>
            <div className={cx('textLabel')}>
              {t('form_shift_schedule_modal_client_rate_label')}{' '}
              <span className={cx('labelRequired')}>{ASTERISK_SYMBOL}</span>
            </div>

            <div className={cx('contentWrapRadio')}>
              <div className={cx('radioGroup')}>
                <RadioAddSchedule
                  id={'hourlySelectClientId'}
                  name={'hourlySelectClientId'}
                  label={t('form_shift_schedule_modal_hourly_label')}
                  value={RadioCrmEnum.HOURLY}
                  checked={selectedClientRate === RadioCrmEnum.HOURLY}
                  onChange={handleClientRate}
                />
                <RadioAddSchedule
                  id={'perVisitSelectClientId'}
                  name={'perVisitSelectClientId'}
                  label={t('form_shift_schedule_modal_per_visit_label')}
                  value={RadioCrmEnum.PER_VISIT}
                  checked={selectedClientRate === RadioCrmEnum.PER_VISIT}
                  onChange={handleClientRate}
                />
              </div>
              <Controller
                name={'clientRateId'}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <RateSelect
                    name={'clientRateId'}
                    value={value}
                    options={listClientRate}
                    placeholder={t('common_select_placeholder')}
                    onChange={({ value }) => {
                      onChange(value);
                    }}
                    errorMessage={errors.clientRateId?.message}
                    required
                    isLoading={isFetchingPayrollClient}
                  />
                )}
              />
            </div>
          </div>

          <div className={cx('wrapRadio')}>
            <div className={cx('textLabel')}>
              {t('form_shift_schedule_modal_caregiver_rate_label')}
              <span className={cx('labelRequired')}>{ASTERISK_SYMBOL}</span>
            </div>

            <div className={cx('contentWrapRadio')}>
              <div className={cx('radioGroup')}>
                <RadioAddSchedule
                  id={'hourlySelectCaregiverId'}
                  name={'hourlySelectCaregiverId'}
                  label={t('form_shift_schedule_modal_hourly_label')}
                  value={RadioCrmEnum.HOURLY}
                  checked={selectedCaregiverRate === RadioCrmEnum.HOURLY}
                  onChange={handleCaregiverRate}
                />
                <RadioAddSchedule
                  id={'perVisitSelectCaregiverId'}
                  name={'perVisitSelectCaregiverId'}
                  label={t('form_shift_schedule_modal_per_visit_label')}
                  value={RadioCrmEnum.PER_VISIT}
                  checked={selectedCaregiverRate === RadioCrmEnum.PER_VISIT}
                  onChange={handleCaregiverRate}
                />
              </div>

              <Controller
                name={'caregiverRateId'}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <RateSelect
                    name={'caregiverRateId'}
                    value={value}
                    options={listCaregiverRate}
                    placeholder={t('common_select_placeholder')}
                    onChange={({ value }) => {
                      onChange(value);
                    }}
                    errorMessage={errors.caregiverRateId?.message}
                    required
                    isLoading={isFetchingPayrollCaregiver}
                  />
                )}
              />
            </div>
          </div>

          <div className={cx('formListGroup')}>
            <div className={cx('textLabelTask')}>{t('form_shift_schedule_modal_task_label')}</div>
            {tasksFields.map((row, index) => (
              <div className={cx('containerTask')} key={row.id}>
                <div className={cx('theeCol')}>
                  <Controller
                    name={`tasks.${index}.name`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <BaseInput
                        id={`tasks.${index}.name`}
                        label={t('form_shift_schedule_modal_task_name_label')}
                        value={value || EMPTY_STRING}
                        onChange={onChange}
                        required
                        messageError={errors.tasks?.[index]?.name?.message}
                      />
                    )}
                  />

                  <Controller
                    name={`tasks.${index}.startTime`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <SelectTime
                        name={'startTime'}
                        label={t('form_shift_schedule_modal_start_time_label')}
                        placeholder={t('common_placeholder_select')}
                        value={value || EMPTY_STRING}
                        onChange={onChange}
                      />
                    )}
                  />

                  <Controller
                    name={`tasks.${index}.detail`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Field className={cx('containerInput')}>
                        <Label className={cx('inputLabel')}>{t('form_shift_schedule_modal_detail_label')}</Label>
                        <div className={cx('inputContent')}>
                          <Textarea
                            id={`tasks.${index}.detail`}
                            value={value}
                            className={cx('textInput')}
                            onChange={(e) => {
                              handleSetHeightInputDetail(e);
                              onChange(e);
                            }}
                            style={{ height: inputHeight ?? DEFAULT_INPUT_HEIGHT }}
                          />
                        </div>
                      </Field>
                    )}
                  />
                </div>
                <div className={cx('btnRemoveContainer')}>
                  {tasksFields.length > DEFAULT_NUMBER_ONE && index > DEFAULT_NUMBER_ZERO && (
                    <button type='button' onClick={() => handleRemoveTask(index)}>
                      <img src={icons.commonIconRemoveField} alt={t('common_img_text_alt')} />
                    </button>
                  )}
                </div>
              </div>
            ))}

            <button type='button' onClick={handleAddTask} className={cx('btnAddField', 'group')}>
              <img src={icons.commonIconAddField} alt={t('common_img_text_alt')} className={cx('iconAddField')} />
              <span className={cx('btnTextAdd')}>{t('form_shift_schedule_modal_add_task_label')}</span>
            </button>
          </div>
        </div>

        <div className={cx('footerModal')}>
          <BaseButton text={t('common_cancel_label')} width={65} onClick={handleClose} />

          <BaseButton text={t('common_save_label')} typeStyle={ButtonTypeEnum.PRIMARY} width={80} type='submit' />
        </div>
      </form>

      {isLoading && <Loading />}

      {isShowUnderDevelopment && <ModalUnderDevelopment onClose={handleCloseDevelopment} />}
    </Modal>
  );
};

export default FormShiftScheduleModal;
