// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo } from 'react';
import { LoadingData } from '~/context';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { TFunction } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Components, Layouts, Pages
import { BaseButton, BaseInput } from '~/components';
// Others
import { ButtonTypeEnum, InputTypeEnum, TypeRateEnum } from '~/utils/enum';
import { TPayrollUserType } from '~/utils/type/common';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { getUserName } from '~/utils/helper';
import { IBodyPayrollRate, IFormPayrollRate, IListQueryPayroll, IPayrollRate } from '~/utils/interface/rate';
import { DEFAULT_LIMIT_MAX_ITEM, DEFAULT_NUMBER_ONE, DEFAULT_NUMBER_ZERO, DOLLAR } from '~/utils/constants/common';
import { getListPayroll, updatePayrollRate } from '~/thunks/rate/rateThunk';

// Styles, images, icons
import styles from './PayrollTab.module.scss';

type Props = {
  userType: TPayrollUserType;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup.object({
    payrollRatesHourly: yup.array().optional(),
    payrollRatesPerVisit: yup.array().optional(),
  });
};

const defaultValues: IFormPayrollRate = {
  payrollRatesHourly: [],
  payrollRatesPerVisit: [],
};

const PayrollTab = (props: Props) => {
  //#region Destructuring Props
  const { userType } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const { clientId, caregiverId } = useParams();

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm<IFormPayrollRate>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValues,
  });
  //#endregion Declare Hook

  //#region Selector
  const { caregiverDetail } = useAppSelector((state) => state.caregiverState);
  const { clientDetail } = useAppSelector((state) => state.clientState);
  const { isRefreshPayrollRates } = useAppSelector((state) => state.rateState);
  //#endregion Selector

  //#region Declare State
  const userName = useMemo(() => {
    return userType === 'caregiver'
      ? getUserName(caregiverDetail?.firstName, caregiverDetail?.lastName)
      : getUserName(clientDetail?.firstName, clientDetail?.lastName);
  }, [userType, caregiverDetail, clientDetail]);
  const payrollRatesHourly = useMemo(() => {
    return getValues('payrollRatesHourly') || [];
  }, [watch('payrollRatesHourly')]);
  const payrollRatesPerVisit = useMemo(() => {
    return getValues('payrollRatesPerVisit') || [];
  }, [watch('payrollRatesPerVisit')]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    loading?.show();
    fetchPayroll(clientId || caregiverId);
  }, [clientId, caregiverId]);

  useEffect(() => {
    if (isRefreshPayrollRates) {
      fetchPayroll(clientId || caregiverId);
    }
  }, [isRefreshPayrollRates]);
  //#endregion Implement Hook

  //#region Handle Function
  const fetchPayroll = (userId?: string) => {
    if (!userId) return;

    const params: IListQueryPayroll = {
      page: DEFAULT_NUMBER_ONE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
      userType: userType.toUpperCase(),
      rateType: TypeRateEnum.HOURLY,
      userId,
    };

    [TypeRateEnum.HOURLY, TypeRateEnum.PER_VISIT].forEach((rateType) => handleGetListPayroll({ ...params, rateType }));
  };

  const handleGetListPayroll = (params: IListQueryPayroll) => {
    dispatch(getListPayroll(params))
      .unwrap()
      .then((res) => {
        const { data, rateType } = res;

        switch (rateType) {
          case TypeRateEnum.HOURLY:
            setValue('payrollRatesHourly', data.data.responses);
            break;
          case TypeRateEnum.PER_VISIT:
            setValue('payrollRatesPerVisit', data.data.responses);
            break;
        }
      })
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleSubmitPayroll = async (data: IFormPayrollRate) => {
    if (!caregiverId && !clientId) return;
    loading?.show();

    try {
      const payload: IBodyPayrollRate = {
        userId: caregiverId || clientId,
        userType: userType.toUpperCase(),
        rates: getPayrollRates(data),
      };

      await dispatch(updatePayrollRate(payload)).unwrap();
    } finally {
      loading?.hide();
    }
  };

  const getPayrollRates = (data: IFormPayrollRate): IPayrollRate[] => {
    const hourlyRates: IPayrollRate[] =
      data.payrollRatesHourly?.map((item) => ({
        rateId: item.rateId.toString(),
        price: item.price || DEFAULT_NUMBER_ZERO,
      })) || [];

    const perVisitRates: IPayrollRate[] =
      data.payrollRatesPerVisit?.map((item) => ({
        rateId: item.rateId.toString(),
        price: item.price || DEFAULT_NUMBER_ZERO,
      })) || [];

    return [...hourlyRates, ...perVisitRates];
  };
  //#endregion Handle Function

  return (
    <div id='payrollTabComponent' className={cx('payrollTabComponent')}>
      <form id='payrollTabComponent' className={cx('container')} onSubmit={handleSubmit(handleSubmitPayroll)}>
        <div className={cx('content')}>
          <h1 className={cx('title')}>{t('settings_payroll_rate_question_content', { userName: userName })}</h1>
          <p className={cx('questionContent')}>{t('settings_payroll_rate_attention_content')}</p>

          <h1 className={cx('title')}>
            {t('settings_payroll_rate_hourly_title')} <span className={cx('titleDollar')}>{`(${DOLLAR})`}</span>
          </h1>
          <div className={cx('payRollList')}>
            {payrollRatesHourly.length > DEFAULT_NUMBER_ZERO ? (
              payrollRatesHourly.map((payrollHourly, index) => {
                return (
                  <div key={payrollHourly.id} className={cx('itemPayRoll')}>
                    <div>{payrollHourly.name}</div>
                    <Controller
                      name={`payrollRatesHourly.${index}.price`}
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <BaseInput
                            id={`payrollRatesHourly.${index}.price`}
                            value={value}
                            type={InputTypeEnum.NUMBER}
                            textAlign='right'
                            onChange={onChange}
                            width={110}
                          />
                        );
                      }}
                    />
                  </div>
                );
              })
            ) : (
              <div>{t('settings_payroll_rate_no_hourly_title')}</div>
            )}
          </div>

          <h1 className={cx('title')}>
            {t('settings_payroll_rate_per_visit_title')} <span className={cx('titleDollar')}>{`(${DOLLAR})`}</span>
          </h1>
          <div>
            <div className={cx('payRollList')}>
              {payrollRatesPerVisit.length > DEFAULT_NUMBER_ZERO ? (
                payrollRatesPerVisit.map((payrollPerVisit, index) => {
                  return (
                    <div key={payrollPerVisit.id} className={cx('itemPayRoll')}>
                      <div>{payrollPerVisit.name}</div>
                      <Controller
                        name={`payrollRatesPerVisit.${index}.price`}
                        control={control}
                        render={({ field: { value, onChange } }) => {
                          return (
                            <BaseInput
                              id={`payrollRatesPerVisit.${index}.price`}
                              value={value}
                              type={InputTypeEnum.NUMBER}
                              textAlign='right'
                              onChange={onChange}
                              width={110}
                            />
                          );
                        }}
                      />
                    </div>
                  );
                })
              ) : (
                <div>{t('settings_payroll_rate_no_per_visit_title')}</div>
              )}
            </div>
          </div>
        </div>

        <div className={cx('footerButton')}>
          <BaseButton
            text={t('common_save_label')}
            type='submit'
            typeStyle={ButtonTypeEnum.PRIMARY}
            width={80}
            disabled={!isDirty}
          />
        </div>
      </form>
    </div>
  );
};

export default PayrollTab;
