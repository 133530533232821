// Libs
import { TFunction } from 'i18next';
import * as yup from 'yup';
// Others
import { EMPTY_STRING, FILE_UPLOAD_LIMIT_50_MB, RegExp } from '~/utils/constants/common';
import { IFormEmployees } from '~/utils/interface/employees';

export const defaultValues: IFormEmployees = {
  firstName: EMPTY_STRING,
  lastName: EMPTY_STRING,
  email: EMPTY_STRING,
  role: EMPTY_STRING,
  location: [],
  address: EMPTY_STRING,
  addressLine2: EMPTY_STRING,
  avatarAccount: [],
  city: EMPTY_STRING,
  lat: 0,
  lng: 0,
  phoneNumber: EMPTY_STRING,
  state: EMPTY_STRING,
  zipCode: EMPTY_STRING,
};

export const schemaEmployees = (t: TFunction) => {
  return yup.object({
    avatarAccount: yup
      .array()
      .of(
        yup.object({
          file: yup
            .mixed<File>()
            .optional()
            .test('fileSize', t('add_caregiver_files_size_limit'), (value) => {
              if (!value) return true;
              return value instanceof File && value.size <= FILE_UPLOAD_LIMIT_50_MB;
            }),
        })
      )
      .optional(),
    firstName: yup.string().trim().required(t('common_required_field')),
    lastName: yup.string().trim().required(t('common_required_field')),
    email: yup
      .string()
      .required(t('common_required_field'))
      .test('isValidEmail', t('common_validate_invalid_email'), (value) => {
        if (value && !RegExp.EMAIL.test(value)) return false;
        return true;
      }),
    role: yup.string().trim().required(t('common_required_field')),
    location: yup
      .array(yup.string().required(t('common_required_field')))
      .min(1, t('common_required_field'))
      .required(),
    phoneNumber: yup.string().optional(),
    address: yup.string().trim().optional(),
    addressLine2: yup.string().trim().optional(),
    city: yup.string().trim().optional(),
    state: yup.string().trim().optional(),
    zipCode: yup.string().trim().optional(),
  });
};
