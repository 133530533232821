// Libs
import classNames from 'classnames/bind';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Components, Layouts, Pages
import {
  BaseButton,
  BaseFilter,
  BasePagination,
  BaseSelect,
  BaseTable,
  FormSendEmailModal,
  InputSearch,
  ModalChangeStatus,
  ModalUnderDevelopment,
  RingCentralConfirmModal,
  StackedAvatars,
  StatusLabel,
  ThreeDotTableOptions,
  ToolBar,
} from '~/components';
// Others
import {
  AccountRoleCodesEnum,
  ButtonTypeEnum,
  CLientStatusEnum,
  ConnectionStatusEnum,
  CRMEnum,
  RelatesClientTypeEnum,
  StatusClientFilterEnum,
  StatusEnum,
  StorageEnum,
  TranslationEnum,
} from '~/utils/enum';
import {
  ColumnTableType,
  IBaseOption,
  IFilterValueChange,
  IListDataResponse,
  IPaginationResponse,
} from '~/utils/interface/common';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_LIMIT_PAGE,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { convertEnumToString, formatPhoneNumber, getUserName } from '~/utils/helper';
import {
  IFilterClient,
  IListClient,
  IListQueryParamsClients,
  IPayloadChangeStatusClient,
} from '~/utils/interface/crm/clients';
import { changeStatusClient, getListClients } from '~/thunks/crm/clients/clientsThunk';
import { useAppDispatch } from '~/redux/hooks';
import { LoadingData, RingCentralContext } from '~/context';
import { useSearchParams } from 'react-router-dom';
import useDebounce from '~/utils/customHook';
import {
  adminRoute,
  adminRouteAbsolute,
  caregiverRouteAbsolute,
  staffRouteAbsolute,
  superAdminRouteAbsolute,
} from '~/utils/constants/route';
import { clientActions, selectIsRefreshClientList } from '~/thunks/crm/clients/clientsSlice';
import { optionsStatusClient } from '~/mockData';
// Styles, images, icons
import styles from './CRMClient.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  handleViewDetails: (id: string) => void,
  handleEditClient: (id: string) => void,
  handleCall: (record: IListClient) => void,
  handleSendEmail: (record: IListClient) => void,
  handleSendMessage: (record: IListClient) => void,
  handleShowChangeStatus: (record: IListClient) => void
): ColumnTableType<IListClient>[] => {
  return [
    {
      key: 'clientName',
      title: t('crm_client_table_title_name'),
      render: (_, record: IListClient) => {
        // return !record.firstName && !record.lastName ? (
        //   <>{EMPTY_STRING}</>
        // ) : (
        //   <>
        //     <ImageCircle
        //       type={record?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
        //       imageUrl={record?.avatarUrl}
        //       firstName={record?.firstName}
        //       lastName={record?.lastName}
        //       width={24}
        //       height={24}
        //       fontSize={14}
        //       margin={'0 6px 0 0'}
        //     />
        //     <span>{getUserName(record?.firstName, record?.lastName)}</span>
        //   </>
        // );
        return <>{getUserName(record?.firstName, record?.middleName, record?.lastName)}</>;
      },
    },
    {
      key: 'phoneNumber',
      title: t('crm_client_table_title_phone_number'),
      dataIndex: 'phoneNumber',
      render(value, record, index) {
        return <>{record?.phoneNumber ? formatPhoneNumber(record?.phoneNumber) : EMPTY_STRING}</>;
      },
    },

    {
      key: 'location',
      title: t('crm_client_table_title_location'),
      dataIndex: 'location',
      render(value, record, index) {
        return <>{record.location?.name || EMPTY_STRING}</>;
      },
    },
    {
      key: 'types',
      title: t('crm_client_table_title_types'),
      render(value, record, index) {
        return <>{record.careClientType ? convertEnumToString(record.careClientType) : EMPTY_STRING}</>;
      },
    },
    {
      key: 'caregivers',
      title: t('crm_client_table_title_caregivers'),
      dataIndex: 'caregivers',
      render(value, record, index) {
        if (!record.caregivers || record.caregivers.length === DEFAULT_NUMBER_ZERO) {
          return EMPTY_STRING;
        }

        return <StackedAvatars color='green' avatars={record.caregivers} id={record.id} />;
      },
    },
    {
      key: 'primaryManager',
      title: t('crm_client_table_title_primary_manager'),
      dataIndex: 'primaryManager',
      render: (value, record) => {
        return <>{EMPTY_STRING}</>;

        // return !record.primaryManager ? (
        //   <>{EMPTY_STRING}</>
        // ) : (
        //   <>
        //      <ImageCircle
        //       type={record?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
        //       imageUrl={record?.avatarUrl}
        //       firstName={record?.firstName}
        //       lastName={record?.lastName}
        //       width={24}
        //       height={24}
        //       fontSize={14}
        //       margin={'0 6px 0 0'}
        //     />
        //     <span>{getUserName(record?.firstName, record?.lastName)}</span>
        //   </>
        // );
      },
    },
    {
      key: 'status',
      title: t('crm_client_table_title_status'),
      dataIndex: 'status',
      render(value, record, index) {
        return (
          <div className={cx('buttonStatus')}>
            <StatusLabel
              borderRadius={100}
              label={record.status === StatusEnum.ACTIVE ? t('crm_client_table_status_activate_label') : record.status}
              bgOpacity={0.1}
            />
          </div>
        );
      },
    },
    {
      key: 'action',
      title: t('crm_client_table_title_action'),
      render(value, record: IListClient, index) {
        return (
          <ThreeDotTableOptions
            data={record}
            permissions={{
              isCall: true,
              isSendMessage: true,
              isSendEmail: true,
              isEdit: true,
              isDelete: false,
              isChangeStatus: true,
            }}
            onView={() => handleViewDetails(record.id)}
            onEdit={() => handleEditClient(record.id)}
            onCall={() => handleCall(record)}
            onSendEmail={() => handleSendEmail(record)}
            onSendMessage={() => handleSendMessage(record)}
            onChangeStatus={() => handleShowChangeStatus(record)}
          />
        );
      },
      width: 50,
    },
  ];
};

const CRMClient = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const navigate = useNavigate();
  const { t } = useTranslation();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const role = localStorage.getItem(StorageEnum.ROLE);
  // const { ringCentralStatus, executeCall, sendSMS } = useContext(RingCentralContext);
  //#endregion Declare Hook

  //#region Selector
  const isRefreshClientsList = useSelector(selectIsRefreshClientList);
  //#endregion Selector

  //#region Declare State
  const [clientList, setClientList] = useState<IListClient[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const textSearchParams = useMemo<string>(() => String(params?.textSearch || EMPTY_STRING), [params?.textSearch]);
  const statusParams = useMemo<string>(() => String(params?.status || EMPTY_STRING), [params?.status]);

  const [searchKey, setSearchKey] = useState<string>(textSearchParams || EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey.trim() || EMPTY_STRING, DEFAULT_DELAY_TIME);
  const [clientSelected, setClientSelected] = useState<IListClient | null>(null);

  const [isShowSendMail, setIsShowSendMail] = useState<boolean>(false);
  const [isShowChangeStatus, setIsShowChangeStatus] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<CLientStatusEnum | null>(null);
  // const [openDisconnectConfirm, setOpenDisconnectConfirm] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const { status, ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(params?.status === StatusClientFilterEnum.ALL ? {} : { status: status }),
      type: [CRMEnum.CLIENT],
    };

    handleGetListClients(newParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!textSearchParams) setSearchKey(EMPTY_STRING);
  }, [textSearchParams]);

  useEffect(() => {
    if (!pagination) return;

    if (debouncedSearchKey) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
        textSearch: debouncedSearchKey,
      });
    } else {
      const { textSearch, ...rest } = params;
      setSearchParams(rest);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (!isRefreshClientsList) return;

    const { status, ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(debouncedSearchKey ? { searchKey: debouncedSearchKey } : {}),
      ...(params?.status === StatusClientFilterEnum.ALL ? {} : { status: status }),
      type: [CRMEnum.CLIENT],
    };

    handleGetListClients(newParams);
    dispatch(clientActions.setRefreshList(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshClientsList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleViewDetails = (id: string) => {
    if (!id) return;

    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRouteAbsolute.crmClient}/${id}`);
        return;
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.crmClient}/${id}`);
        return;

      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRouteAbsolute.crmClient}/${id}`);
        return;

      case AccountRoleCodesEnum.CAREGIVER:
        navigate(`${caregiverRouteAbsolute.crmClient}/${id}`);
        return;

      default:
        return;
    }
  };

  const handleCall = async (record: IListClient) => {
    // if (ringCentralStatus !== ConnectionStatusEnum.CONNECTED) {
    //   setOpenDisconnectConfirm(true);
    //   return;
    // }

    // if (record?.phoneNumber) {
    //   executeCall && executeCall(record?.phoneNumber);
    // }

    setIsShowModalUnderDevelopment(true);
  };

  const handleSendMessage = (record: IListClient) => {
    // if (ringCentralStatus !== ConnectionStatusEnum.CONNECTED) {
    //   setOpenDisconnectConfirm(true);
    //   return;
    // }

    // if (record?.phoneNumber) {
    //   sendSMS && sendSMS(record?.phoneNumber);
    // }

    setIsShowModalUnderDevelopment(true);
  };

  const handleShowSendEmail = (record: IListClient) => {
    if (!record) return;

    setClientSelected(record);
    setIsShowSendMail(true);
  };

  const handleCloseSendEmail = () => {
    setClientSelected(null);
    setIsShowSendMail(false);
  };

  const handleShowChangeStatus = (record: IListClient) => {
    if (!record) return;

    setClientSelected(record);
    setIsShowChangeStatus(true);
  };

  const handleCloseChangeStatus = () => {
    setClientSelected(null);
    setIsShowChangeStatus(false);
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleGetListClients = (params: IListQueryParamsClients) => {
    loading?.show();

    dispatch(getListClients(params))
      .unwrap()
      .then((res) => {
        const { responses, pagination }: IListDataResponse<IListClient[]> = res?.data;
        setClientList(responses);
        setPagination(pagination);
      })
      .catch((error) => {})
      .finally(() => loading?.hide());
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
  };

  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleSortBy = () => {
    // TODO - HuyPahmGRF-ABD: Handle Sort By
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleSubmitFilter = (valueFilter: IFilterClient | undefined) => {
    if (!valueFilter) {
      return;
    }

    const { status, ...restParams } = params;

    setSearchParams({
      ...restParams,
      page: DEFAULT_NUMBER_ONE.toString(),
      limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
      ...(valueFilter?.status === StatusClientFilterEnum.ALL ? {} : { status: valueFilter?.status }),
    });
  };

  const handleAddClient = () => {
    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRouteAbsolute.addClient}`, { state: { type: RelatesClientTypeEnum.CLIENT } });
        return;
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.addClient}`, { state: { type: RelatesClientTypeEnum.CLIENT } });
        return;
    }
  };

  const handleEditClient = (clientId: string) => {
    if (!clientId) return;

    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRouteAbsolute.editClient}/${clientId}`);
        return;
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.editClient}/${clientId}`);
        return;
    }
  };

  // const handleCloseDisconnectConfirm = () => {
  //   setOpenDisconnectConfirm(false);
  // };

  // const handleNavigateToRingCentral = () => {
  //   const role = localStorage.getItem(StorageEnum.ROLE);

  //   switch (role) {
  //     case AccountRoleCodesEnum.ADMIN:
  //       navigate(adminRouteAbsolute.ringCentral);
  //       break;

  //     case AccountRoleCodesEnum.EMPLOYEE:
  //       break;

  //     case AccountRoleCodesEnum.CAREGIVER:
  //       break;

  //     default:
  //       break;
  //   }
  // };

  const handleClientRowClick = (data: IListClient) => {
    if (!data || !data?.id) return;

    handleViewDetails(data?.id);
  };
  //#endregion Handle Function

  return (
    <div id='CRMClientTemplate' className={cx('CRMClientComponent')}>
      <ToolBar
        title={
          <div className={cx('crmTitle')}>
            <span>{t('crm_client_title')}</span>

            {/* {ringCentralStatus !== ConnectionStatusEnum.CONNECTED && (
              <span className={cx('crmRingCentralStatus')} onClick={handleNavigateToRingCentral}>
                {t('ring_central_confirm_disconnect_title')}
              </span>
            )} */}
          </div>
        }
      >
        {/* <BaseButton
          iconLeft={icons.commonIconSort}
          text={t('common_text_sort')}
          width={84}
          height={36}
          onClick={handleSortBy}
        /> */}

        <InputSearch
          height={36}
          placeholder={t('common_placeholder_search_by_name')}
          onChange={handleChangeSearch}
          value={searchKey || EMPTY_STRING}
        />
        <BaseFilter<IFilterClient>
          defaultValue={{ status: StatusClientFilterEnum.ALL }}
          onApply={handleSubmitFilter}
          valueFilter={{ status: statusParams || StatusClientFilterEnum.ALL }}
        >
          {({ valueFilter, onChange }) => {
            return (
              <div className={cx('filterWrap')}>
                <div className={cx('contentFilter')}>
                  <span className={cx('statusLabel')}>{t('crm_filter_client_status_title')}</span>
                  <BaseSelect
                    value={valueFilter?.status}
                    options={optionsStatusClient}
                    onChange={(option) => {
                      onChange({
                        name: 'status',
                        value: option?.value.toString(),
                      });
                    }}
                  />
                </div>
              </div>
            );
          }}
        </BaseFilter>
        <BaseButton
          typeStyle={ButtonTypeEnum.PRIMARY}
          iconLeft={icons.commonIconPlus}
          text={t('crm_add_client_title')}
          height={36}
          onClick={handleAddClient}
        />
      </ToolBar>

      <div className={cx('content')}>
        <div className={cx('tableWrap')}>
          <BaseTable
            columns={columns(
              t,
              handleViewDetails,
              handleEditClient,
              handleCall,
              handleShowSendEmail,
              handleSendMessage,
              handleShowChangeStatus
            )}
            dataSource={clientList ?? []}
            onClickRow={handleClientRowClick}
          />
        </div>

        <div className={cx('paginationTable')}>
          <BasePagination
            onChange={handlePaginationChange}
            defaultCurrentPage={pageSelected}
            totalItems={pagination?.totalItems}
            totalPages={pagination?.totalPages}
          />
        </div>
      </div>

      {isShowSendMail && (
        <FormSendEmailModal isOpen={isShowSendMail} dataDefault={clientSelected} onClose={handleCloseSendEmail} />
      )}

      {isShowChangeStatus && (
        <ModalChangeStatus isOpen={isShowChangeStatus} dataDefault={clientSelected} onClose={handleCloseChangeStatus} />
      )}

      {/* {openDisconnectConfirm && (
        <RingCentralConfirmModal
          isOpen={openDisconnectConfirm}
          title={t('ring_central_confirm_disconnect_title')}
          description={t('ring_central_confirm_disconnect_description')}
          confirm={t('ring_central_confirm_disconnect_confirm')}
          onClose={handleCloseDisconnectConfirm}
          onConfirm={handleNavigateToRingCentral}
        />
      )} */}

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}
    </div>
  );
};

export default CRMClient;
