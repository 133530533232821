import { createSlice } from '@reduxjs/toolkit';
import { getListPayroll, createRate, getListRate, updatePayrollRate } from './rateThunk';
import { RootState } from '~/redux/store';
import { TypeUserEnum } from '~/utils/enum';
import { IBaseOption } from '~/utils/interface/common';
import { DOLLAR, EMPTY_STRING } from '~/utils/constants/common';

export interface RateState {
  isRefreshRateList: boolean;
  isRefreshPayrollRates: boolean;
  listClientRate: IBaseOption[];
  listCaregiverRate: IBaseOption[];
  isFetchingPayrollClient: boolean;
  isFetchingPayrollCaregiver: boolean;
}

const initialState: RateState = {
  isRefreshRateList: false,
  isRefreshPayrollRates: false,
  listClientRate: [],
  listCaregiverRate: [],
  isFetchingPayrollClient: false,
  isFetchingPayrollCaregiver: false,
};

const rateSlice = createSlice({
  name: 'rateState',
  initialState: initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshRateList = action.payload;
    },
    resetPayroll(state) {
      state.listCaregiverRate = initialState.listCaregiverRate;
      state.listClientRate = initialState.listClientRate;
    },
    setFetchingPayrollRates(state, action) {
      const { userType } = action.payload;

      switch (userType) {
        case TypeUserEnum.CLIENT:
          state.isFetchingPayrollClient = true;
          break;

        case TypeUserEnum.CAREGIVER:
          state.isFetchingPayrollCaregiver = true;
          break;
      }
    },
  },

  extraReducers(builder) {
    // Get list Rate
    builder
      .addCase(getListRate.pending, (state) => {})
      .addCase(getListRate.fulfilled, (state, action) => {
        state.isRefreshRateList = false;
      })
      .addCase(getListRate.rejected, (state) => {});

    // Get list Payroll
    builder
      .addCase(getListPayroll.pending, (state) => {})
      .addCase(getListPayroll.fulfilled, (state, action) => {
        const { data, userType, rateType } = action.payload;
        switch (userType) {
          case TypeUserEnum.CLIENT:
            state.listClientRate = data.data.responses.map((data) => ({
              label: `${data.name} - ${DOLLAR}${data.price}` || EMPTY_STRING,
              value: data.id,
            }));
            state.isFetchingPayrollClient = false;
            break;

          case TypeUserEnum.CAREGIVER:
            state.listCaregiverRate = data.data.responses.map((data) => ({
              label: `${data.name} - ${DOLLAR}${data.price}` || EMPTY_STRING,
              value: data.id,
            }));
            state.isFetchingPayrollCaregiver = false;
            break;
        }
        state.isRefreshPayrollRates = false;
      })
      .addCase(getListPayroll.rejected, (state) => {});

    // Create Rate
    builder
      .addCase(createRate.pending, (state) => {})
      .addCase(createRate.fulfilled, (state, _action) => {
        state.isRefreshRateList = true;
      })
      .addCase(createRate.rejected, (state) => {});

    // Update Rate
    builder
      .addCase(updatePayrollRate.pending, (state) => {})
      .addCase(updatePayrollRate.fulfilled, (state, _action) => {
        state.isRefreshPayrollRates = true;
      })
      .addCase(updatePayrollRate.rejected, (state) => {});
  },
});

// Actions
export const rateActions = rateSlice.actions;

// Selectors
export const selectIsRefreshRateList = (state: RootState) => state.rateState.isRefreshRateList;

// Reducer
const rateReducer = rateSlice.reducer;

export default rateReducer;
