// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
// Components, Layouts, Pages
import { BaseButton, BaseSelect, ModalUnderDevelopment } from '~/components';
// Others
import { optionsStatusShiftSchedule } from '~/mockData';
import { ButtonTypeEnum, TimeFormatEnum } from '~/utils/enum';
import { convertTime } from '~/utils/helper';
import { DOLLAR, EMPTY_STRING } from '~/utils/constants/common';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { updateShiftSchedule } from '~/thunks/schedule/scheduleThunk';
import { IFormUpdateSchedule } from '~/utils/interface/schedule';
import { IBaseOption } from '~/utils/interface/common';
import { scheduleActions } from '~/thunks/schedule/scheduleSlice';
// Styles, images, icons
import styles from './SummaryTab.module.scss';

type Props = {
  onRefresh?: () => void;
};

const SummaryTab = (props: Props) => {
  //#region Destructuring Props
  const { onRefresh } = props;
  const cx = classNames.bind(styles);
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  const { detailShift } = useAppSelector((state) => state.scheduleState);
  //#endregion Selector

  //#region Declare State
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleClockIn = () => {
    setIsShowModalUnderDevelopment(true);
  };

  const getRangeDate = () => {
    if (!detailShift) {
      return EMPTY_STRING;
    }

    const startDate = `${detailShift.startDate} ${detailShift.startTime}`;
    const endDate = `${detailShift.endDate} ${detailShift.endTime}`;

    const rangeDate = `${convertTime(new Date(startDate), TimeFormatEnum.MM_DD_YYYY_HH_AM_PM)} - ${convertTime(
      new Date(endDate),
      TimeFormatEnum.MM_DD_YYYY_HH_AM_PM
    )}`;
    return rangeDate;
  };

  const handleUpdateStatus = (option: IBaseOption) => {
    if (!detailShift) return;

    const payload: IFormUpdateSchedule = {
      id: detailShift?.id,
      status: option.value.toString(),
    };

    dispatch(updateShiftSchedule(payload))
      .unwrap()
      .then((_res) => {
        dispatch(scheduleActions.setRefreshShiftSchedules(true));
        onRefresh && onRefresh();
      })
      .catch((_error) => {});
  };
  //#endregion Handle Function

  return (
    <div id='summaryTabComponent' className={cx('summaryTabComponent')}>
      <div className={cx('contentBody')}>
        <div className={cx('contentLabelValue')}>
          <div className={cx('contentLabel')}>{t('summary_schedule_form_dates_label')}</div>
          <div className={cx('contentValueDates')}>{getRangeDate()}</div>
        </div>

        <div className={cx('contentLabelValue', 'contentStatus')}>
          <div className={cx('contentLabel')}>{t('summary_schedule_form_status_label')}</div>
          <span className={cx('contentValue')}>
            <BaseSelect
              options={optionsStatusShiftSchedule}
              width={250}
              value={detailShift?.status}
              placeholder={t('common_select_placeholder')}
              onChange={handleUpdateStatus}
            />
          </span>
        </div>

        <div className={cx('contentLabelValue')}>
          <div className={cx('contentLabel')}>{t('summary_schedule_form_tasks_label')}</div>
          <div className={cx('tasksContainer')}>
            {detailShift?.tasks?.map((value, index) => (
              <div className={cx('contentValueNameTasks')} key={index}>
                <span className={cx('label')} />
                {`${
                  value.startTime ? `${convertTime(value?.startTime, TimeFormatEnum.HOUR_MINUTE_AM_PM_NORMAL)} - ` : ''
                }${value.name}`}
                <span className={cx('contentValue')}>
                  {value?.detail && t('summary_schedule_detail_task', { detailTask: value?.detail })}
                </span>
              </div>
            ))}
          </div>
        </div>

        {/* <div className={cx('contentLabelValue')}>
          <div className={cx('contentLabel')}>{t('summary_schedule_form_schedule_label')}</div>
          <div className={cx('contentValue')}>{dataSummary.schedule}</div>
        </div> */}

        <div className={cx('contentLabelValue')}>
          <div className={cx('contentLabel')}>{t('summary_schedule_form_authorization_label')}</div>
          <div className={cx('contentValue')}>
            {detailShift?.clientRate?.name
              ? `${detailShift?.clientRate?.name} - ${DOLLAR}${detailShift?.clientRate?.price}`
              : EMPTY_STRING}
          </div>
        </div>

        <div className={cx('contentLabelValue')}>
          <div className={cx('contentLabel')}>{t('summary_schedule_form_rate_name_label')}</div>
          <div className={cx('contentValue')}>
            {detailShift?.caregiverRate?.name
              ? `${detailShift?.caregiverRate?.name} - ${DOLLAR}${detailShift?.caregiverRate?.price}`
              : EMPTY_STRING}
          </div>
        </div>
      </div>

      <div className={cx('line')} />

      <div className={cx('footerButton')}>
        <BaseButton
          onClick={handleClockIn}
          typeStyle={ButtonTypeEnum.OUT_LINE}
          text={t('summary_tab_clock_in_button')}
          minWidth={80}
        />

        <div className={cx('groupButton')}>
          <BaseButton
            onClick={handleClockIn}
            typeStyle={ButtonTypeEnum.PRIMARY}
            text={t('common_edit_label')}
            minWidth={80}
          />
          <BaseButton onClick={handleClockIn} typeStyle={ButtonTypeEnum.OUT_LINE} text={t('tab_copy')} minWidth={80} />
          <BaseButton
            onClick={handleClockIn}
            typeStyle={ButtonTypeEnum.DELETE}
            text={t('common_delete_label')}
            minWidth={80}
          />
        </div>
      </div>

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}
    </div>
  );
};

export default SummaryTab;
