// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { dateControl } from '~/mockData';
import { TCalendarViewMode } from '~/utils/type/schedule';
import { capitalizeFirstLetter } from '../helper';
import { WHITE } from '~/utils/constants/color';
// Styles, images, icons
import styles from './HeaderMySchedule.module.scss';
import { CommonIconNext, CommonIconPrev } from '~/assets/svgComponents';

type Props = {
  onPreviousTime: () => void;
  onNextTime: () => void;
  onGoToday: () => void;
  onChangeCalendar: (view: TCalendarViewMode) => void;
  calendarViewMode: TCalendarViewMode;
  currentTime: string;
};

const cx = classNames.bind(styles);

const HeaderMySchedule = (props: Props) => {
  //#region Destructuring Props
  const { onChangeCalendar, onNextTime, onPreviousTime, onGoToday, calendarViewMode, currentTime } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='headerSchedule' className={cx('container')}>
      <div className={cx('headerScheduleWrapper')}>
        <div className={cx('headerScheduleLeft')}>
          <div className={cx('dateControl')}>
            <button className={cx('dateControlBtn')} onClick={onPreviousTime}>
              <CommonIconPrev strokePath={WHITE} />
            </button>
            <button className={cx('dateControlBtn')} onClick={onNextTime}>
              <CommonIconNext strokePath={WHITE} />
            </button>
          </div>
          <button className={cx('dateControlBtnToday')} onClick={onGoToday}>
            {t('common_text_today')}
          </button>
        </div>

        <div className={cx('label')}>{currentTime}</div>

        <div className={cx('headerScheduleRight')}>
          <div className={cx('modeViewCalendar')}>
            {dateControl.map((date, index) => {
              return (
                <button
                  key={index}
                  className={cx('btnCalendar', calendarViewMode === date && 'active')}
                  onClick={() => onChangeCalendar(date)}
                >
                  {capitalizeFirstLetter(date)}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderMySchedule;
