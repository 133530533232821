import { urlApiRates } from '~/utils/constants/api';
import axiosClient from './axiosClient';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import {
  IListQueryParamsRate,
  IListQueryPayroll,
  IPayroll,
  IRate,
  IFormRate,
  IBodyPayrollRate,
} from '~/utils/interface/rate';

const rateApi = {
  getListRate(params: IListQueryParamsRate) {
    const url = `${urlApiRates.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IRate[]>>>(url, { params });
  },

  getListPayroll(params: IListQueryPayroll) {
    const url = `${urlApiRates.getListPayroll}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IPayroll[]>>>(url, { params });
  },

  createRate(body: IFormRate) {
    const url = `${urlApiRates.createRate}`;
    return axiosClient.post<BaseResponse>(url, body);
  },
  updatePayrollRate(body: IBodyPayrollRate) {
    const url = `${urlApiRates.updatePayrollRate}`;
    return axiosClient.put<BaseResponse>(url, body);
  },
};
export default rateApi;
