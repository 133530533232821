import { statusColor } from '../constants/color';
import { EventStatusEnum } from '../enum';

export const generateGuid = (): string => {
  const generateSegment = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };

  const guid =
    generateSegment() +
    generateSegment() +
    '-' +
    generateSegment() +
    '-' +
    generateSegment() +
    '-' +
    generateSegment() +
    '-' +
    generateSegment() +
    generateSegment() +
    generateSegment();

  return guid;
};

export const handleEventStatusColor = (value?: string): string => {
  switch (value) {
    case EventStatusEnum.NOT_SCHEDULED:
      return statusColor.RED_800;
    case EventStatusEnum.CANCELLED_BY_CLIENT:
      return statusColor.INDIGO_400;
    case EventStatusEnum.CANCELLED_BY_CAREGIVER:
      return statusColor.ORANGE_300;
    case EventStatusEnum.CANCELLED_BY_OFFICE:
      return statusColor.ORANGE_400;
    case EventStatusEnum.PENDING_CONFIRMATION:
      return statusColor.EMERALD_300;
    case EventStatusEnum.ATTENTION_REQUIRED:
      return statusColor.ROSE_500;
    case EventStatusEnum.TENTATIVE:
      return statusColor.FINN900;
    case EventStatusEnum.SCHEDULED:
      return statusColor.YELLOW_500;
    case EventStatusEnum.COMPLETE:
      return statusColor.GREEN_800;
    case EventStatusEnum.NOT_COMPLETE:
      return statusColor.ROSE_600;
    case EventStatusEnum.GENERAL:
      return statusColor.LIME_100;
    case EventStatusEnum.MEETING:
      return statusColor.INDIGO_300;
    case EventStatusEnum.VACATION_TIME_OFF:
      return statusColor.EMERALD_300;
    case EventStatusEnum.HOLIDAY:
      return statusColor.ORANGE_300;
    case EventStatusEnum.TASK:
      return statusColor.SKY_300;
    default:
      return statusColor.ROSE_400;
  }
};
