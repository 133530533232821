// Libs
import classNames from 'classnames/bind';
import { memo, ReactNode, useMemo, useState } from 'react';
import { Event, EventProps, Views } from 'react-big-calendar';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseScheduleNew, HeaderMySchedule } from '~/components';
// Others
import { BaseCalendarProps, TCalendarViewMode } from '~/utils/type/schedule';
import { DateFormatEnum } from '~/utils/enum';
import { DEFAULT_VIEW_MODE } from '~/utils/constants/common';
// Styles, images, icons
import styles from './MySchedule.module.scss';
import './MySchedule.scss';

type Props<T> = Omit<BaseCalendarProps, 'view' | 'date' | 'onSelectEvent' | 'events'> & {
  events: Event[];
  date: string;
  onSelectEvent: (event: T) => void;
  childrenEvent: (event: T) => ReactNode;
  onTimeChange: (timeValue: string) => void;
  onCalendarViewMode: (view: TCalendarViewMode) => void;
};

const cx = classNames.bind(styles);

const MySchedule = <T,>(props: Props<T>) => {
  //#region Destructuring Props
  const {
    ref,
    events,
    date,
    onSelectEvent,
    childrenEvent,
    components,
    onTimeChange,
    onCalendarViewMode,
    ...restProps
  } = props;
  //#endregion Destructuring Props
  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [calendarViewMode, setCalendarViewMode] = useState<TCalendarViewMode>(DEFAULT_VIEW_MODE);
  const [currentTime, setCurrentTime] = useState<string>(moment().format(DateFormatEnum.YYYY_MM_DD));
  //#endregion Declare State

  //#region Implement Hook
  const timeValue = useMemo(() => {
    switch (calendarViewMode) {
      case Views.WEEK:
        const startOfWeek = moment(currentTime).startOf('week');
        const endOfWeek = moment(currentTime).endOf('week');
        const formattedWeek = `${startOfWeek.format('MMMM DD')} - ${endOfWeek.format('DD, YYYY')}`;
        return formattedWeek;
      case Views.DAY:
        return moment(currentTime).format('dddd, MMMM YYYY');
      default:
        return moment(currentTime).format('MMMM, YYYY');
    }
  }, [calendarViewMode, currentTime]);
  //#endregion Implement Hook

  //#region Handle Function
  const goPreviousTime = () => {
    const previousTime = moment(currentTime).subtract(1, calendarViewMode);
    setCurrentTime(previousTime.format(DateFormatEnum.YYYY_MM_DD));
    onTimeChange(previousTime.format(DateFormatEnum.YYYY_MM_DD));
  };

  const goNextTime = () => {
    const nextTime = moment(currentTime).add(1, calendarViewMode);
    setCurrentTime(nextTime.format(DateFormatEnum.YYYY_MM_DD));
    onTimeChange(nextTime.format(DateFormatEnum.YYYY_MM_DD));
  };

  const goToday = () => {
    setCurrentTime(moment().format(DateFormatEnum.YYYY_MM_DD));
    onTimeChange(moment().format(DateFormatEnum.YYYY_MM_DD));
  };

  const handleChangeCalendarView = (newValue: TCalendarViewMode): void => {
    setCalendarViewMode(newValue);
    setCurrentTime(moment().format(DateFormatEnum.YYYY_MM_DD));
    onCalendarViewMode(newValue);
  };
  //#endregion Handle Function

  return (
    <div id='myScheduleComponent' className={cx('myScheduleContainer')}>
      <div className={cx('bodyWrap')}>
        <HeaderMySchedule
          currentTime={timeValue}
          calendarViewMode={calendarViewMode}
          onChangeCalendar={handleChangeCalendarView}
          onGoToday={goToday}
          onNextTime={goNextTime}
          onPreviousTime={goPreviousTime}
        />

        <div className={cx('scheduleWrap')}>
          <div className={cx('schedule')}>
            <div
              className={cx(
                { 'h-[780px]': calendarViewMode === Views.MONTH },
                { myScheduleWeekView: calendarViewMode === Views.WEEK },
                { myScheduleDayView: calendarViewMode === Views.DAY }
              )}
            >
              <BaseScheduleNew
                events={events}
                selectable={true}
                toolbar={false}
                date={date}
                view={calendarViewMode}
                components={{
                  event: ({ event }: EventProps) => {
                    if (calendarViewMode === Views.MONTH) {
                      return (
                        <div className={cx('monthEventWrap')}>
                          {event?.resource?.map((eventData: T, index: number) => (
                            <div key={index} onClick={() => onSelectEvent(eventData)}>
                              {childrenEvent(eventData)}
                            </div>
                          ))}
                        </div>
                      );
                    }

                    if (calendarViewMode === Views.WEEK) {
                      return (
                        <div className={cx(`weekEventWrap`)}>
                          {event?.resource?.map((eventData: T, index: number) => (
                            <div className={cx('eventContainer')} key={index} onClick={() => onSelectEvent(eventData)}>
                              {childrenEvent(eventData)}
                            </div>
                          ))}
                        </div>
                      );
                    }

                    if (calendarViewMode === Views.DAY) {
                      return (
                        <div className={cx(`dayEventWrap`)}>
                          {event?.resource?.map((eventData: T, index: number) => (
                            <div className={cx('eventContainer')} key={index} onClick={() => onSelectEvent(eventData)}>
                              {childrenEvent(eventData)}
                            </div>
                          ))}
                        </div>
                      );
                    }
                  },
                }}
                {...restProps}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(MySchedule) as <T>(props: Props<T>) => JSX.Element;
