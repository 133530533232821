// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// Components, Layouts, Pages
import { ImageCircle } from '~/components';
// Others
import { AccountRoleCodesEnum, ImageCircleTypeEnum, StorageEnum } from '~/utils/enum';
import { getRoleFromCode, getUserName } from '~/utils/helper';
import { IUserAccount } from '~/utils/interface/user';
import {
  adminRouteAbsolute,
  caregiverRouteAbsolute,
  staffRouteAbsolute,
  superAdminRouteAbsolute,
} from '~/utils/constants/route';
// Styles, images, icons
import styles from './ProfileHeader.module.scss';
import { icons } from '~/assets';

type Props = {
  onClose: () => void;
  onLogout: () => void;
  data: IUserAccount;
};

const cx = classNames.bind(styles);

const ProfileHeader = (props: Props) => {
  //#region Destructuring Props
  const { onClose, data, onLogout } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleOpenChangePassword = () => {
    const role = localStorage.getItem(StorageEnum.ROLE);

    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(superAdminRouteAbsolute.changePassword);
        break;
      case AccountRoleCodesEnum.ADMIN:
        navigate(adminRouteAbsolute.changePassword);
        break;
      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(staffRouteAbsolute.changePassword);
        break;
      case AccountRoleCodesEnum.CAREGIVER:
        navigate(caregiverRouteAbsolute.changePassword);
        break;

      default:
        break;
    }

    onClose();
  };

  const handleOpenSettings = () => {
    const role = localStorage.getItem(StorageEnum.ROLE);

    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(superAdminRouteAbsolute.settings);
        break;
      case AccountRoleCodesEnum.ADMIN:
        navigate(adminRouteAbsolute.settings);
        break;
      case AccountRoleCodesEnum.EMPLOYEE:
        break;
      case AccountRoleCodesEnum.CAREGIVER:
        break;

      default:
        break;
    }

    onClose();
  };
  //#endregion Handle Function

  return (
    <div id='profileHeaderComponent' className={cx('profileHeaderContainer')}>
      <div className={cx('container')}>
        <div className={cx('avatarFirstLastName')}>
          <ImageCircle
            type={data.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
            width={40}
            height={40}
            fontSize={20}
            firstName={data.firstName}
            lastName={data.lastName}
            imageUrl={data.avatarUrl}
            margin={'0px 10px 0px 0px'}
          />
          <div className={cx('information')}>
            <p className={cx('firstLastName')}>{getUserName(data.firstName, data.lastName)}</p>
            <p className={cx('roleName')}>{getRoleFromCode(data.role)}</p>
          </div>
        </div>

        <ul className={cx('menu')}>
          <li className={cx('menuItem')} onClick={handleOpenChangePassword}>
            <div className={cx('menuItemGroup')}>
              <img src={icons.signInIconPassword} alt={t('common_img_text_alt')} className={cx('menuItemIcon')} />
              <span className={cx('menuItemLabel')}>{t('menu_change_password')}</span>
            </div>
          </li>
          <li className={cx('menuItem')} onClick={handleOpenSettings}>
            <div className={cx('menuItemGroup')}>
              <img src={icons.commonIconSettings} alt={t('common_img_text_alt')} className={cx('menuItemIcon')} />
              <span className={cx('menuItemLabel')}>{t('menu_settings')}</span>
            </div>
          </li>
          {/* <li className={cx('menuItem')}>
              <div className={cx('menuItemGroup')} onClick={handleOpenSetting}>
                <img src={icons.commonIconSettings} alt={t('common_img_text_alt')} className={cx('menuItemIcon')} />
                <span className={cx('menuItemLabel')}>{t('menu_settings')}</span>
              </div>
            </li> */}
        </ul>

        <div className={cx('line')}></div>

        <div className={cx('signOut')} onClick={onLogout}>
          <img src={icons.commonIconLogout} alt={t('common_img_text_alt')} className={cx('signOutIcon')} />
          <span className={cx('signOutLabel')}>{t('common_btn_logout')}</span>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
