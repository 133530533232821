// Libs
import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
  timelineOppositeContentClasses,
} from '@mui/lab';
import { useNavigate, useParams } from 'react-router-dom';

// Components, Layouts, Pages
import {
  Spinner,
  ModalUnderDevelopment,
  DetailActivityModal,
  BaseDrawerModal,
  EmployeeDetailModalItem,
} from '~/components';

// Others
import { LoadingData } from '~/context';
import {
  DEFAULT_CURRENT_PAGE,
  EMPTY_STRING,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_ZERO,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
} from '~/utils/constants/common';
import { useAppDispatch } from '~/redux/hooks';
import { convertTime, getUserName } from '~/utils/helper';
import {
  AccountRoleCodesEnum,
  ActivityDetailEMAILEnum,
  ActivityTypeEnum,
  BusinessModelEnum,
  CRMEnum,
  StorageEnum,
  TimeFormatEnum,
} from '~/utils/enum';
import { GREEN_PEA700 } from '~/utils/constants/color';
import { IActivityByUser, IParamsGetListActivity, IRelates } from '~/utils/interface/activity';
import { getListActivityByCaregiver, getListActivityByClient } from '~/thunks/activity/activityThunk';
import {
  adminRoute,
  adminRouteAbsolute,
  caregiverRouteAbsolute,
  staffRouteAbsolute,
  superAdminRoute,
  superAdminRouteAbsolute,
} from '~/utils/constants/route';

// Styles, images, icons
import styles from './DetailActivities.module.scss';
import { icons } from '~/assets';

const cx = classNames.bind(styles);

type Props = {};

const DetailActivities = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingContext = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const activitiesListRef = useRef<HTMLDivElement | null>(null);
  const { clientId, caregiverId } = useParams();
  const role = localStorage.getItem(StorageEnum.ROLE);
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [totalPages, setTotalPages] = useState<number>(DEFAULT_CURRENT_PAGE);
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_CURRENT_PAGE);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [isShowModalViewDetails, setIsShowModalViewDetails] = useState<boolean>(false);
  const [itemActivity, setItemActivity] = useState<IActivityByUser>();
  const [isShowUnderDevelopment, setIsShowUnderDevelopment] = useState<boolean>(false);
  const [activities, setActivities] = useState<IActivityByUser[]>([]);
  const currentPageRef = useRef(currentPage);
  const totalPagesRef = useRef(totalPages);
  const activitiesRef = useRef(activities);
  const isLoadingMoreRef = useRef(isLoadingMore);
  const [assigneeSelected, setAssigneeSelected] = useState<string>(EMPTY_STRING);
  const [isShowAssigneeDetail, setIsShowAssigneeDetail] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    currentPageRef.current = currentPage;
    totalPagesRef.current = totalPages;
    activitiesRef.current = activities;
    isLoadingMoreRef.current = isLoadingMore;
  }, [currentPage, totalPages, activities, isLoadingMore]);

  useEffect(() => {
    if (!clientId && !caregiverId) return;

    const payload: IParamsGetListActivity = {
      userId: clientId || caregiverId!,
      params: {
        page: DEFAULT_CURRENT_PAGE,
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
      },
    };

    loadingContext?.show();

    clientId && handleGetListActivityByClient(payload);
    caregiverId && handleGetListActivityByCaregiver(payload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, caregiverId]);

  useEffect(() => {
    const activitiesElement = activitiesListRef.current;

    if (activitiesElement) {
      activitiesElement.addEventListener('scroll', handleScroll);

      return () => {
        activitiesElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleScroll = () => {
    if (activitiesListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = activitiesListRef.current;

      if (scrollTop + clientHeight >= scrollHeight) {
        handleLoadMore();
      }
    }
  };

  const handleLoadMore = () => {
    if (!clientId && !caregiverId) return;

    if (!isLoadingMoreRef.current && currentPageRef.current < totalPagesRef.current) {
      setIsLoadingMore(true);
      const params: IParamsGetListActivity = {
        userId: clientId || caregiverId!,
        params: {
          page: currentPageRef.current + 1,
          limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
        },
      };

      clientId && handleGetListActivityByClient(params);
      caregiverId && handleGetListActivityByCaregiver(params);
    }
  };

  const groupedActivities = React.useMemo(() => {
    const grouped: Record<string, typeof activities> = {};

    activities.forEach((activity) => {
      const activityDate = convertTime(activity?.createdAt, TimeFormatEnum.YYYY_MM_DD);
      if (!grouped[activityDate]) {
        grouped[activityDate] = [];
      }
      grouped[activityDate].push(activity);
    });

    return Object.entries(grouped).map(([date, activities]) => ({
      date,
      activities,
    }));
  }, [activities]);

  const handleToggleModalUnderDevelopment = () => {
    setIsShowUnderDevelopment(!isShowUnderDevelopment);
  };

  const renderIcon = (businessModel?: string) => {
    switch (businessModel) {
      case BusinessModelEnum.TASK:
        return (
          <div className={cx('iconContent')}>
            <img src={icons.commonIconNote} alt={t('common_img_alt')} className={cx('datePickerIcon')} />
          </div>
        );
      case BusinessModelEnum.EMAIL:
        return (
          <div className={cx('iconContent')}>
            <img src={icons.commonIconEmailActivities} alt={t('common_img_alt')} className={cx('datePickerIcon')} />
          </div>
        );
      case BusinessModelEnum.DOCUMENT:
        return (
          <div className={cx('iconContent')}>
            <img src={icons.commonIconDocsActivities} alt={t('common_img_alt')} className={cx('datePickerIcon')} />
          </div>
        );
      default:
        return <TimelineDot style={{ background: GREEN_PEA700 }} />;
    }
  };

  const renderTitle = (activityType: string) => {
    switch (activityType) {
      case ActivityTypeEnum.UPLOAD_DOCUMENT:
        return <span className={cx('contentName')}>{t('activities_upload_document_title')}</span>;

      default:
        return <div className={cx('contentName')}>{EMPTY_STRING}</div>;
    }
  };

  const renderContent = (activity: IActivityByUser) => {
    switch (activity.actionType) {
      case ActivityTypeEnum.SEND_EMAIL:
        return (
          <div className={cx('actions')}>
            <div className={cx('contentNameGroup')}>
              <span className={cx('contentName')}>
                {t('activities_sent_email_subject_title', {
                  emailSubject: activity.detail?.find((item) => item.name === ActivityDetailEMAILEnum.SUBJECT)
                    ?.newValue,
                })}
              </span>
              <span className={cx('contentCreatedBy')}>
                {t('common_by_title', {
                  name: getUserName(activity.createdBy?.firstName, activity.createdBy?.lastName),
                })}
              </span>
            </div>

            <div className={cx('details')} onClick={() => handleToggleViewDetails(activity)}>
              {t(`common_view_detail_title`)}
            </div>
          </div>
        );

      case ActivityTypeEnum.CREATE_TASK:
        return (
          <div className={cx('actions')}>
            <div className={cx('contentNameGroup')}>
              <span className={cx('contentName')}>
                {t('activities_created_task_title')}
                {activity?.isDeleted && <span className={cx('contentNameDeleted')}> {t('activity_task_deleted')}</span>}
              </span>

              <div className={cx('contentText')}>
                {t('activities_assigned_to_title')}
                <span>
                  {activity.task?.assignees?.map((assignee, index) => {
                    return (
                      <span
                        key={index}
                        onClick={() => handleViewAssigneeDetail(assignee?.id)}
                        className={cx('textLink')}
                      >
                        {getUserName(assignee?.firstName, assignee?.middleName, assignee?.lastName)}
                        {index < activity.task?.assignees?.length - DEFAULT_NUMBER_ONE && ', '}
                      </span>
                    );
                  })}
                </span>
              </div>

              {activity.task?.description && (
                <span className={cx('contentCreatedBy')}>{activity.task?.description}</span>
              )}

              <div className={cx('contentText')}>
                {t('activities_relates_to_title')}
                <span>
                  {activity.task?.relates?.map((relate, index) => {
                    return (
                      <span key={index} onClick={() => handleViewRelatesDetail(relate)} className={cx('textLink')}>
                        {getUserName(relate?.firstName, relate?.middleName, relate?.lastName)}
                        {index < activity.task?.relates?.length - DEFAULT_NUMBER_ONE && ', '}
                      </span>
                    );
                  })}
                </span>
              </div>

              <span className={cx('contentCreatedBy')}>
                {t('common_by_title', {
                  name: getUserName(activity.createdBy?.firstName, activity.createdBy?.lastName),
                })}
              </span>
            </div>

            <div className={cx('details')} onClick={() => handleToggleViewDetails(activity)}>
              {t(`common_view_detail_title`)}
            </div>
          </div>
        );

      default:
        return (
          <>
            <div className={cx('actions')}>
              <div className={cx('contentNameGroup')}>
                {renderTitle(activity.actionType)}
                <span className={cx('contentCreatedBy')}>
                  {t('common_by_title', {
                    name: getUserName(activity.createdBy?.firstName, activity.createdBy?.lastName),
                  })}
                </span>
              </div>

              <div className={cx('details')} onClick={() => handleToggleViewDetails(activity)}>
                {t(`common_view_detail_title`)}
              </div>
            </div>
          </>
        );
    }
  };

  const handleGetListActivityByClient = (payload: IParamsGetListActivity) => {
    dispatch(getListActivityByClient(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const { pagination, responses } = res?.data;

        setTotalPages(pagination?.totalPages);

        if (isLoadingMoreRef.current) {
          setCurrentPage(pagination?.page);
          setActivities([...activitiesRef.current, ...responses]);
          return;
        }

        setActivities(responses);
      })
      .catch((_error) => {})
      .finally(() => {
        loadingContext?.hide();
        setIsLoadingMore(false);
      });
  };

  const handleGetListActivityByCaregiver = (payload: IParamsGetListActivity) => {
    dispatch(getListActivityByCaregiver(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const { pagination, responses } = res?.data;

        setTotalPages(pagination?.totalPages);

        if (isLoadingMoreRef.current) {
          setCurrentPage(pagination?.page);
          setActivities([...activitiesRef.current, ...responses]);
          return;
        }

        setActivities(responses);
      })
      .catch((_error) => {})
      .finally(() => {
        loadingContext?.hide();
        setIsLoadingMore(false);
      });
  };

  const handleToggleViewDetails = (item?: IActivityByUser) => {
    setIsShowModalViewDetails(true);
    setItemActivity(item);
  };

  const handleCloseViewDetail = () => {
    setIsShowModalViewDetails(false);
  };

  const handleCloseAssigneeDetail = () => {
    setIsShowAssigneeDetail(false);
    setAssigneeSelected(EMPTY_STRING);
  };

  const handleViewAssigneeDetail = (id: string) => {
    if (!id) return;

    setIsShowAssigneeDetail(true);
    setAssigneeSelected(id);
  };

  const handleViewRelatesDetail = (data: IRelates) => {
    if (!data || !role) return;

    if (data?.userType === CRMEnum.CLIENT) {
      switch (role) {
        case AccountRoleCodesEnum.SUPER_ADMIN:
          navigate(`${superAdminRouteAbsolute.crmClient}/${data?.id}`);
          return;
        case AccountRoleCodesEnum.ADMIN:
          navigate(`${adminRouteAbsolute.crmClient}/${data?.id}`);
          return;

        case AccountRoleCodesEnum.EMPLOYEE:
          navigate(`${staffRouteAbsolute.crmClient}/${data?.id}`);
          return;

        default:
          return;
      }
    }

    if (data?.userType === CRMEnum.PROSPECT) {
      switch (role) {
        case AccountRoleCodesEnum.SUPER_ADMIN:
          navigate(`${superAdminRouteAbsolute.crmProspect}/${data?.id}`);
          return;
        case AccountRoleCodesEnum.ADMIN:
          navigate(`${adminRouteAbsolute.crmProspect}/${data?.id}`);
          return;

        case AccountRoleCodesEnum.EMPLOYEE:
          navigate(`${staffRouteAbsolute.crmProspect}/${data?.id}`);
          return;

        default:
          return;
      }
    }

    if (data?.userType === CRMEnum.REFERRAL) {
      switch (role) {
        case AccountRoleCodesEnum.SUPER_ADMIN:
          navigate(`${superAdminRouteAbsolute.crmReferralSource}/${data?.id}`);
          return;
        case AccountRoleCodesEnum.ADMIN:
          navigate(`${adminRouteAbsolute.crmReferralSource}/${data?.id}`);
          return;

        case AccountRoleCodesEnum.EMPLOYEE:
          navigate(`${staffRouteAbsolute.crmReferralSource}/${data?.id}`);
          return;

        default:
          return;
      }
    }

    if (data?.userType === CRMEnum.USER) {
      setIsShowAssigneeDetail(true);
      setAssigneeSelected(data.id);
    }
  };

  const handleEditInfo = (id: string) => {
    if (!id) return;
    setIsShowAssigneeDetail(false);

    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRoute.base}${superAdminRoute.employees}${superAdminRoute.editEmployees}/${id}`);
        break;

      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRoute.base}${adminRoute.employees}${adminRoute.editEmployees}/${id}`);
        break;
    }
  };
  //#endregion Handle Function

  return (
    <div id='detailActivitiesComponent' className={cx('container')} ref={activitiesListRef}>
      <Timeline
        sx={{
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: 0.1,
          },
        }}
        className={cx('containerContent')}
      >
        {groupedActivities && groupedActivities.length > DEFAULT_NUMBER_ZERO ? (
          <>
            {groupedActivities.map(({ date, activities }) => (
              <div key={date}>
                <TimelineItem>
                  <TimelineOppositeContent>
                    <div className={cx('containerDay')}>{convertTime(date, TimeFormatEnum.MM_DD_YYYY)}</div>
                  </TimelineOppositeContent>
                  <TimelineSeparator className={cx('timelineSeparator')}>
                    <TimelineDot style={{ background: GREEN_PEA700 }} />
                    <TimelineConnector
                      className={cx('timelineConnector')}
                      style={{ background: GREEN_PEA700, width: DEFAULT_NUMBER_ONE }}
                    />
                  </TimelineSeparator>
                  <TimelineContent></TimelineContent>
                </TimelineItem>

                {activities.map((activity, index) => (
                  <TimelineItem key={activity.id}>
                    <TimelineOppositeContent>
                      <div className={cx('contentDate')}>
                        {convertTime(activity.createdAt, TimeFormatEnum.HOUR_MINUTE_AM_PM)}
                      </div>
                    </TimelineOppositeContent>

                    <TimelineSeparator>
                      {renderIcon(activity.businessModel)}

                      {index !== activities.length - DEFAULT_NUMBER_ONE && (
                        <TimelineConnector
                          className={cx('timelineConnector')}
                          style={{ background: GREEN_PEA700, width: DEFAULT_NUMBER_ONE }}
                        />
                      )}
                    </TimelineSeparator>

                    <TimelineContent>{renderContent(activity)}</TimelineContent>
                  </TimelineItem>
                ))}
              </div>
            ))}
            <div className={cx('containerSpinner')}>{isLoadingMore && <Spinner />}</div>
          </>
        ) : (
          <div className={cx('noDataAvailable')}>{t('common_empty_data')}</div>
        )}
      </Timeline>

      {isShowModalViewDetails && itemActivity && (
        <DetailActivityModal
          detailActivity={itemActivity}
          isOpen={isShowModalViewDetails}
          onClose={handleCloseViewDetail}
        />
      )}
      {isShowUnderDevelopment && <ModalUnderDevelopment onClose={handleToggleModalUnderDevelopment} />}

      <BaseDrawerModal isOpen={isShowAssigneeDetail} onClose={handleCloseAssigneeDetail}>
        <EmployeeDetailModalItem accountId={assigneeSelected} onEditInfo={handleEditInfo} />
      </BaseDrawerModal>
    </div>
  );
};

export default DetailActivities;
