// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Components, Layouts, Pages
import {
  BaseButton,
  BaseDrawerModal,
  BaseFilter,
  BasePagination,
  BaseTable,
  ConfirmModal,
  CrmUpdateClientModal,
  EmployeeDetailModalItem,
  FormTaskModal,
  InputSearch,
  ModalUnderDevelopment,
  StackedAvatars,
  StatisticCard,
  StatusLabel,
  ThreeDotTableOptions,
  ToolBar,
} from '~/components';
// Context
import { LoadingData } from '~/context';
// Others
import { listCareAlertMock, satisfiedCustomers, totalRating, totalService } from '~/mockData';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_FILTER_TASK,
  DEFAULT_LIMIT_PAGE,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_ZERO,
  DEFAULT_SHORT_LIMIT_PAGE,
  EMPTY_STRING,
} from '~/utils/constants/common';
import {
  AccountRoleCodesEnum,
  ButtonTypeEnum,
  CRMEnum,
  StatusEnum,
  TimeFormatEnum,
  TranslationEnum,
  FieldTypeEnum,
  FilterParamTaskEnum,
  RadioCrmEnum,
  StorageEnum,
} from '~/utils/enum';
import {
  ColumnTableType,
  IFilterValueChange,
  IListDataResponse,
  IPaginationResponse,
  IQueryParams,
} from '~/utils/interface/common';
import { convertDateToFormatTime, convertEnumToString, formatPhoneNumber, getUserName } from '~/utils/helper';
import useDebounce from '~/utils/customHook';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import {
  adminRoute,
  adminRouteAbsolute,
  caregiverRouteAbsolute,
  staffRouteAbsolute,
  staffRoute,
  superAdminRouteAbsolute,
  superAdminRoute,
} from '~/utils/constants/route';
import { getListClients } from '~/thunks/crm/clients/clientsThunk';
import { changeStatusAccount } from '~/thunks/user/userThunk';
import { clientActions } from '~/thunks/crm/prospect/prospectSlice';
import { IFormUpdateStatusAccount } from '~/utils/interface/user';
import { createConversation } from '~/thunks/conversation/conversationThunk';
import { ICreateConversationReq } from '~/utils/interface/conversation';
import { IListClient, IListQueryParamsClients } from '~/utils/interface/crm/clients';
import { selectIsRefreshClientList } from '~/thunks/crm/clients/clientsSlice';
import { getTotalDashboard } from '~/thunks/dashboard/dashboardThunk';
import { deleteTask, getListTask } from '~/thunks/task/taskThunk';
import { selectIsRefreshTaskList, taskActions } from '~/thunks/task/taskSlice';
import { IDefaultFilterTask, IQueryListTask, ITask, ITaskDetailRelates } from '~/utils/interface/task';
import { IStatisticTotal } from '~/utils/interface/dashboard';
import { IMockListCareAlert } from '~/mockData/mockInterface';
import { ICareAlerts } from '~/utils/interface/careAlert';
// Styles, images, icons
import styles from './Home.module.scss';
import { icons } from '~/assets';

type Props = {
  role: AccountRoleCodesEnum;
};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  handleEdit: (record: IListClient) => void,
  handleDeactivate: (record: IListClient) => void,
  handleViewDetails: (record: IListClient) => void,
  handleSendMessage: (record: IListClient) => void,
  role: AccountRoleCodesEnum
): ColumnTableType<IListClient>[] => {
  return [
    {
      key: 'clientName',
      title: t('crm_client_table_title_name'),
      render: (_, record: IListClient) => {
        const renderName = () => {
          if (record.type === CRMEnum.REFERRAL && record.referralRole === RadioCrmEnum.ORGANIZATION) {
            return record.organization;
          }

          return getUserName(record?.firstName, record?.middleName, record?.lastName);
        };

        return <>{renderName()}</>;
      },
    },
    {
      key: 'phoneNumber',
      title: t('crm_client_table_title_phone_number'),
      dataIndex: 'phoneNumber',
      render(_, record) {
        return (
          <>
            {record.type === CRMEnum.REFERRAL
              ? formatPhoneNumber(record?.workPhone)
              : formatPhoneNumber(record?.phoneNumber) || EMPTY_STRING}
          </>
        );
      },
    },
    {
      key: 'location',
      title: t('crm_client_table_title_location'),
      dataIndex: 'location',
      render(_, record) {
        return <>{record.location?.name || EMPTY_STRING}</>;
      },
    },
    {
      key: 'caregivers',
      title: t('crm_client_table_title_caregivers'),
      dataIndex: 'caregivers',
      render(_, record) {
        if (!record.caregivers || record.caregivers.length === DEFAULT_NUMBER_ZERO) {
          return EMPTY_STRING;
        }

        return <StackedAvatars color='green' avatars={record.caregivers} id={record.id} />;
      },
    },
    {
      key: 'primaryManager',
      title: t('crm_client_table_title_primary_manager'),
      dataIndex: 'primaryManager',
      render: (_, record) => {
        return <>{EMPTY_STRING}</>;
      },
    },
    {
      key: 'status',
      title: t('crm_client_table_title_status'),
      dataIndex: 'status',
      render(value, record, index) {
        return (
          <div className={cx('buttonStatus')}>
            <StatusLabel
              borderRadius={100}
              label={record.status === StatusEnum.ACTIVE ? t('crm_client_table_status_activate_label') : record.status}
              bgOpacity={0.1}
            />
          </div>
        );
      },
    },
    {
      key: 'action',
      title: t('crm_client_table_title_action'),
      render(_, record: IListClient) {
        return (
          <ThreeDotTableOptions
            data={record}
            permissions={{
              isDeactivate: false,
              isSendMessage: role === AccountRoleCodesEnum.ADMIN || role === AccountRoleCodesEnum.SUPER_ADMIN,
              isView: role === AccountRoleCodesEnum.ADMIN || role === AccountRoleCodesEnum.SUPER_ADMIN,
              isDelete: false,
            }}
            onView={() => handleViewDetails(record)}
            onEdit={() => handleEdit(record)}
            onDeactivate={() => handleDeactivate(record)}
            onSendMessage={() => handleSendMessage(record)}
          />
        );
      },
      width: 50,
    },
  ];
};

const columnsTask = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  role: string,
  handleEdit: (record: ITask) => void,
  handleDelete: (record: ITask) => void,
  onViewAssigneeDetail: (id: string) => void,
  onViewRelatesDetail: (record: ITaskDetailRelates) => void
): ColumnTableType<ITask>[] => {
  return [
    {
      key: 'dueDate',
      title: t('task_page_table_due_date_label'),
      render(value, record) {
        return (
          <>{record.dueDate ? convertDateToFormatTime(record.dueDate, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}</>
        );
      },
      width: '15%',
    },
    {
      key: 'assignee',
      title: t('task_page_table_assignee_label'),
      render: (value, record) => {
        return (
          record?.assignees && (
            <div>
              {record?.assignees?.map((assignee, index) => (
                <span
                  key={assignee?.id}
                  onClick={(e) => {
                    e.stopPropagation();
                    onViewAssigneeDetail(String(assignee?.id));
                  }}
                  className={cx('tableLink')}
                >
                  {assignee?.firstName}
                  {index < record?.assignees?.length - DEFAULT_NUMBER_ONE && ', '}
                </span>
              ))}
            </div>
          )
        );
      },
      width: '20%',
    },
    {
      key: 'description',
      title: t('task_page_table_description_label'),
      render: (value, record, index) => {
        return (
          <div className={cx('textTable')}>
            {record?.description ? <span>{record?.description}</span> : EMPTY_STRING}
          </div>
        );
      },
    },
    {
      key: 'relates',
      title: t('task_modal_task_related_to_label'),
      render: (value, record, index) => {
        return (
          record?.relates && (
            <div>
              {record?.relates.map((relate, index) => (
                <span
                  key={relate?.id}
                  className={cx('tableLink')}
                  onClick={(e) => {
                    e.stopPropagation();
                    onViewRelatesDetail(relate);
                  }}
                >
                  {relate?.firstName}
                  {index < record?.relates?.length - DEFAULT_NUMBER_ONE && ', '}
                </span>
              ))}
            </div>
          )
        );
      },
      width: '20%',
    },
    {
      key: 'action',
      title: t('common_text_action'),
      render(value, record, index) {
        return (
          <ThreeDotTableOptions
            data={record}
            permissions={{
              isEdit:
                role === AccountRoleCodesEnum.ADMIN ||
                role === AccountRoleCodesEnum.EMPLOYEE ||
                role === AccountRoleCodesEnum.SUPER_ADMIN,
              isDelete: role === AccountRoleCodesEnum.ADMIN || role === AccountRoleCodesEnum.SUPER_ADMIN,
              isView: false,
            }}
            onEdit={() => handleEdit(record)}
            onDelete={() => handleDelete(record)}
          />
        );
      },
      width: 50,
    },
  ];
};

const columnsCareAlert = (t: TFunction<TranslationEnum.TRANSLATION>): ColumnTableType<IMockListCareAlert>[] => {
  return [
    {
      key: 'date',
      title: t('care_alert_date_label'),
      render(value, record, index) {
        return <>{record.date ? convertDateToFormatTime(record.date, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}</>;
      },
      width: '15%',
    },
    {
      key: 'time',
      title: t('care_alert_time_label'),
      render(value, record, index) {
        return (
          <>{record.time ? convertDateToFormatTime(record.time, TimeFormatEnum.HOUR_MINUTE_AM_PM) : EMPTY_STRING}</>
        );
      },
      width: '15%',
    },
    {
      key: 'alert',
      title: t('care_alert_alert_label'),
      render(value, record, index) {
        return <>{record.alert || EMPTY_STRING}</>;
      },
      width: '55%',
    },
  ];
};

const Home = (props: Props) => {
  //#region Destructuring Props
  const { role } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const navigate = useNavigate();
  const { t } = useTranslation();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  const isRefreshClientsList = useSelector(selectIsRefreshClientList);
  const isRefreshTaskList = useAppSelector(selectIsRefreshTaskList);
  //#endregion Selector

  //#region Declare State
  const [clientList, setClientList] = useState<IListClient[]>([]);
  const [totalDashboard, setTotalDashboard] = useState<IStatisticTotal>();
  const [paginationCustomer, setPaginationCustomer] = useState<IPaginationResponse>();
  const [paginationTask, setPaginationTask] = useState<IPaginationResponse>();
  const [paginationCareAlert, setPaginationCareAlert] = useState<IPaginationResponse>();
  const [filterParams, setFilterParams] = useState<IQueryListTask>(DEFAULT_FILTER_TASK);
  const [paramObjectCustomer, setParamObjectCustomer] = useState<IListQueryParamsClients>({
    page: DEFAULT_CURRENT_PAGE,
    limit: DEFAULT_LIMIT_PAGE,
  });
  const [paramObjectTask, setParamObjectTask] = useState<IQueryListTask>({
    page: DEFAULT_CURRENT_PAGE,
    limit: DEFAULT_SHORT_LIMIT_PAGE,
  });
  const [paramObjectCareAlert, setParamObjectCareAlert] = useState<IQueryParams>({
    page: DEFAULT_CURRENT_PAGE,
    limit: DEFAULT_SHORT_LIMIT_PAGE,
  });
  const [customerSearchKey, setCustomerSearchKey] = useState<string>(EMPTY_STRING);
  const [isShowUpdateClientModal, setIsShowUpdateClientModal] = useState<boolean>(false);
  const [clientId, setClientId] = useState<string>(EMPTY_STRING);
  const debouncedCustomerSearchKey = useDebounce<string>(customerSearchKey.trim(), DEFAULT_DELAY_TIME);
  const [isShowTaskModal, setIsShowTaskModal] = useState<boolean>(false);
  const [listTaskData, setListTaskData] = useState<ITask[]>([]);
  const [idTask, setIdTask] = useState<string>(EMPTY_STRING);

  const [assigneeSelected, setAssigneeSelected] = useState<string>();
  const [isShowAssigneeDetail, setIsShowAssigneeDetail] = useState<boolean>(false);
  const [isShowConfirmDeleteTask, setIsShowConfirmDeleteTask] = useState<boolean>(false);
  const [taskSearchKey, setTaskSearchKey] = useState<string>(EMPTY_STRING);
  const debounceTaskSearchKey = useDebounce<string>(taskSearchKey.trim(), DEFAULT_DELAY_TIME);

  const [isShowUnderDevelopment, setIsShowUnderDevelopment] = useState<boolean>(false);

  const [careAlertData, setCareAlertData] = useState<ICareAlerts[]>(listCareAlertMock);

  const fieldsText = useMemo(() => {
    return filterParams.fields
      ? filterParams.fields.map((field) => convertEnumToString(field)).join(', ')
      : EMPTY_STRING;
  }, [filterParams.fields]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetTotalDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newParamObject: IListQueryParamsClients = {
      ...paramObjectCustomer,
      type: [CRMEnum.CLIENT, CRMEnum.REFERRAL],
    };

    handleGetListClients(newParamObject);
    isRefreshClientsList && dispatch(clientActions.setRefreshList(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramObjectCustomer, isRefreshClientsList]);

  useEffect(() => {
    const payload: IQueryListTask = {
      ...paramObjectTask,
    };

    handleGetListTask(payload);
    isRefreshTaskList && dispatch(taskActions.setRefreshList(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramObjectTask, isRefreshTaskList]);

  useEffect(() => {
    if (!paginationCustomer) return;
    setParamObjectCustomer({
      ...(debouncedCustomerSearchKey ? { textSearch: debouncedCustomerSearchKey } : {}),
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_PAGE,
    });
  }, [debouncedCustomerSearchKey]);

  useEffect(() => {
    if (!paginationTask) return;
    setParamObjectTask({
      ...(debounceTaskSearchKey ? { textSearch: debounceTaskSearchKey } : {}),
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_SHORT_LIMIT_PAGE,
      ...(filterParams ? { ...filterParams } : {}),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceTaskSearchKey]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetTotalDashboard = () => {
    loading?.show();
    dispatch(getTotalDashboard())
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        setTotalDashboard(res.data);
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };

  const handleEdit = (record?: IListClient) => {
    setIsShowUpdateClientModal(!isShowUpdateClientModal);
    if (record) {
      setClientId(record?.id);
    }
  };

  const handleChangeStatus = (record: IListClient) => {
    loading?.show();

    const payload: IFormUpdateStatusAccount = {
      clientId: record.id,
      body: {
        status: record?.status === StatusEnum.ACTIVE ? StatusEnum.INACTIVE : StatusEnum.ACTIVE,
      },
    };

    dispatch(changeStatusAccount(payload))
      .unwrap()
      .then((res) => {
        dispatch(clientActions.setRefreshList(true));
      })
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handlePaginationCustomerChange = (page: number) => {
    if (!page) return;
    const newParamObject: IListQueryParamsClients = { ...paramObjectCustomer, page };
    setParamObjectCustomer(newParamObject);
  };

  const handleGetListClients = (params: IListQueryParamsClients) => {
    loading?.show();

    dispatch(getListClients(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const { responses, pagination }: IListDataResponse<IListClient[]> = res.data;
        setClientList(responses);
        setPaginationCustomer({ ...pagination, page: Number(pagination.page), limit: Number(pagination.limit) });
      })
      .catch((error) => {})
      .finally(() => loading?.hide());
  };

  const handleChangeCustomerSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerSearchKey(event.target.value);
  };

  const handleViewDetails = (record: IListClient) => {
    if (!record.id) return;

    if (record?.type === CRMEnum.CLIENT) {
      switch (role) {
        case AccountRoleCodesEnum.SUPER_ADMIN:
          navigate(`${superAdminRouteAbsolute.crmClient}/${record?.id}`);
          return;
        case AccountRoleCodesEnum.ADMIN:
          navigate(`${adminRouteAbsolute.crmClient}/${record?.id}`);
          return;

        case AccountRoleCodesEnum.EMPLOYEE:
          navigate(`${staffRouteAbsolute.crmClient}/${record?.id}`);
          return;

        case AccountRoleCodesEnum.CAREGIVER:
          navigate(`${caregiverRouteAbsolute.crmClient}/${record?.id}`);
          return;

        default:
          return;
      }
    }

    if (record?.type === CRMEnum.REFERRAL) {
      switch (role) {
        case AccountRoleCodesEnum.SUPER_ADMIN:
          navigate(`${superAdminRouteAbsolute.crmReferralSource}/${record?.id}`);
          return;
        case AccountRoleCodesEnum.ADMIN:
          navigate(`${adminRouteAbsolute.crmReferralSource}/${record?.id}`);
          return;

        case AccountRoleCodesEnum.EMPLOYEE:
          navigate(`${staffRouteAbsolute.crmReferralSource}/${record?.id}`);
          return;

        case AccountRoleCodesEnum.CAREGIVER:
          navigate(`${caregiverRouteAbsolute.crmReferralSource}/${record?.id}`);
          return;

        default:
          return;
      }
    }
  };

  const handleSendMessage = (client: IListClient) => {
    const payload: ICreateConversationReq = {
      accountIds: [Number(client.id)],
    };

    loading?.show();

    dispatch(createConversation(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const { conversationId } = res.data;

        const params = new URLSearchParams({ conversationId: conversationId });

        switch (role) {
          case AccountRoleCodesEnum.SUPER_ADMIN:
            navigate(`${superAdminRouteAbsolute.messages}?${params.toString()}`);
            break;
          case AccountRoleCodesEnum.ADMIN:
            navigate(`${adminRouteAbsolute.messages}?${params.toString()}`);
            break;
        }
      })
      .catch((error) => {})
      .finally(() => loading?.hide());
  };

  const handleGetListTask = (payload: IQueryParams) => {
    loading?.show();

    dispatch(getListTask(payload))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        setListTaskData(res.data.responses);
        setPaginationTask(res.data.pagination);
      })
      .catch((error) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleShowTaskModal = () => {
    setIdTask(EMPTY_STRING);
    setIsShowTaskModal(!isShowTaskModal);
  };

  const handleShowConfirmDeleteTask = (item: ITask) => {
    if (!item || !item?.id) return;

    setIdTask(item?.id);
    setIsShowConfirmDeleteTask(true);
  };

  const handleShowEditTask = (record?: ITask) => {
    if (record) {
      setIdTask(record.id);
    }
    setIsShowTaskModal(true);
  };

  const handleViewAssigneeDetail = (id: string) => {
    if (!id) return;

    setIsShowAssigneeDetail(true);
    setAssigneeSelected(id);
  };

  const handleCloseAssigneeDetail = () => {
    setIsShowAssigneeDetail(false);
    setAssigneeSelected(EMPTY_STRING);
  };

  const handleViewRelatesDetail = (data: ITaskDetailRelates) => {
    if (!data || !role) return;

    if (data?.userType === CRMEnum.CLIENT) {
      switch (role) {
        case AccountRoleCodesEnum.SUPER_ADMIN:
          navigate(`${superAdminRouteAbsolute.crmClient}/${data?.id}`);
          return;
        case AccountRoleCodesEnum.ADMIN:
          navigate(`${adminRouteAbsolute.crmClient}/${data?.id}`);
          return;

        case AccountRoleCodesEnum.EMPLOYEE:
          navigate(`${staffRouteAbsolute.crmClient}/${data?.id}`);
          return;

        default:
          return;
      }
    }

    if (data?.userType === CRMEnum.PROSPECT) {
      switch (role) {
        case AccountRoleCodesEnum.SUPER_ADMIN:
          navigate(`${superAdminRouteAbsolute.crmProspect}/${data?.id}`);
          return;
        case AccountRoleCodesEnum.ADMIN:
          navigate(`${adminRouteAbsolute.crmProspect}/${data?.id}`);
          return;

        case AccountRoleCodesEnum.EMPLOYEE:
          navigate(`${staffRouteAbsolute.crmProspect}/${data?.id}`);
          return;

        default:
          return;
      }
    }

    if (data?.userType === CRMEnum.REFERRAL) {
      switch (role) {
        case AccountRoleCodesEnum.SUPER_ADMIN:
          navigate(`${superAdminRouteAbsolute.crmReferralSource}/${data?.id}`);
          return;
        case AccountRoleCodesEnum.ADMIN:
          navigate(`${adminRouteAbsolute.crmReferralSource}/${data?.id}`);
          return;

        case AccountRoleCodesEnum.EMPLOYEE:
          navigate(`${staffRouteAbsolute.crmReferralSource}/${data?.id}`);
          return;

        default:
          return;
      }
    }

    if (data?.userType === CRMEnum.USER) {
      setIsShowAssigneeDetail(true);
      setAssigneeSelected(data.id);
    }
  };

  const handleCloseUnderDevelopment = () => {
    setIsShowUnderDevelopment(false);
  };

  const handleCloseConfirmDeleteTask = () => {
    setIsShowConfirmDeleteTask(false);
  };

  const handleDeleteTask = () => {
    if (!idTask) return;

    loading?.show();

    dispatch(deleteTask(idTask))
      .unwrap()
      .then((res) => {
        return dispatch(taskActions.setRefreshList(true));
      })
      .catch((err) => {})
      .finally(() => {
        handleCloseConfirmDeleteTask();
        loading?.hide();
      });
  };

  const handleTaskPaginationChange = (page: number) => {
    if (!page) return;
    const newParamObject: IQueryListTask = { ...paramObjectTask, page };
    setParamObjectTask(newParamObject);
  };

  const handleSearchTaskChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setTaskSearchKey(value);
  };

  const handleCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    onChange: (data: IFilterValueChange<IDefaultFilterTask>) => void,
    valueFilter: IDefaultFilterTask | undefined
  ) => {
    const { name, value, checked } = event.target;
    const newFields = {
      ...filterParams,
      fields: checked
        ? [...(valueFilter?.fields ?? []), value]
        : (valueFilter?.fields ?? []).filter((field) => field !== value),
    };

    if (name === FilterParamTaskEnum.FIELDS) {
      onChange({ name: FilterParamTaskEnum.FIELDS, value: newFields.fields });
    }
  };

  const handleFilterChange = (appliedFilter: IDefaultFilterTask | undefined) => {
    if (!appliedFilter) {
      setFilterParams({});
      return;
    }

    setFilterParams({
      ...appliedFilter,
    });

    if (debounceTaskSearchKey)
      setParamObjectTask({
        ...paramObjectTask,
        ...appliedFilter,
      });
  };

  const handleNavigateCareAlert = () => {
    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRoute.base}/${superAdminRoute.careAlertsShowAll}`);
        break;
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRoute.base}/${adminRoute.careAlertsShowAll}`);
        break;
      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRoute.base}/${staffRoute.careAlertsShowAll}`);
        break;

      default:
        break;
    }
  };

  const handlePaginationCareAlert = (page: number) => {
    if (!page) return;
    const newParamObject: IQueryParams = { ...paramObjectCustomer, page };
    setParamObjectCareAlert(newParamObject);
  };

  const handleEditInfo = (id: string) => {
    if (!id) return;
    setIsShowAssigneeDetail(false);

    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRoute.base}${superAdminRoute.employees}${superAdminRoute.editEmployees}/${id}`);
        break;

      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRoute.base}${adminRoute.employees}${adminRoute.editEmployees}/${id}`);
        break;
    }
  };

  const handleCustomerRowClick = (data: IListClient) => {
    if (!data) return;

    handleViewDetails(data);
  };

  const handleTaskRowClick = (data: ITask) => {
    if (!data) return;

    handleShowEditTask(data);
  };
  //#endregion Handle Function

  return (
    <div id='homePage' className={cx('container')}>
      <div className={cx('headerDashboard')}>
        <StatisticCard title={t('dashboard_statistic_card_total_customer')} data={totalDashboard?.customer || {}} />
        <StatisticCard title={t('dashboard_statistic_card_total_service')} data={totalService} />
        <StatisticCard title={t('dashboard_statistic_card_total_rating')} data={totalRating} />
        <StatisticCard title={t('dashboard_statistic_card_satisfied_customers')} data={satisfiedCustomers} />
      </div>

      <div className={cx('chartDashboard')}>
        <div className={cx('columnChart')}>
          <div className={cx('headContent')}>
            <div className={cx('titleWrap')}>
              <span className={cx('titleContent')}>{t('care_alerts_show_all_care_alerts_title')}</span>
            </div>

            <div className={cx('buttonGroup')}>
              <BaseButton
                text={t('common_text_view_all')}
                typeStyle={ButtonTypeEnum.ONLY_TEXT}
                iconRight={icons.commonIconNext}
                onClick={handleNavigateCareAlert}
              />
            </div>
          </div>

          <div className={cx('bodyWrap')}>
            <BaseTable columns={columnsCareAlert(t)} dataSource={careAlertData} />
          </div>

          <div className={cx('paginationTable')}>
            <BasePagination
              onChange={handlePaginationCareAlert}
              // Update Remove DEFAULT_CURRENT_DATE Later
              defaultCurrentPage={paginationCareAlert?.page || DEFAULT_CURRENT_PAGE}
              totalItems={paginationCareAlert?.totalItems || DEFAULT_CURRENT_PAGE}
              totalPages={paginationCareAlert?.totalPages || DEFAULT_CURRENT_PAGE}
            />
          </div>
        </div>

        <div className={cx('donutChart')}>
          {/* <DonutChart
            labels={[]}
            data={donutChartData}
            title={t('dashboard_chart_rating_statistic_title')}
            colors={[FINN900, GREEN_PEA700, GRAY_NURSE100]}
          /> */}

          <div className={cx('tableToolbar')}>
            <ToolBar fontSize={20} title={t('task_page_title')}>
              <InputSearch
                placeholder={t('tasks_text_search_placeholder', {
                  params: fieldsText,
                })}
                height={36}
                onChange={handleSearchTaskChange}
              />

              <BaseFilter<IDefaultFilterTask>
                onApply={handleFilterChange}
                defaultValue={DEFAULT_FILTER_TASK}
                valueFilter={filterParams}
              >
                {({ valueFilter, onChange }) => {
                  return (
                    <div className={cx('filterWrap')}>
                      <div className={cx('filterItemCheckbox')}>
                        <input
                          id='description'
                          type='checkbox'
                          name='fields'
                          onChange={(e) => {
                            handleCheckboxChange(e, onChange, valueFilter);
                          }}
                          value={FieldTypeEnum.DESCRIPTION}
                          checked={valueFilter?.fields?.includes(FieldTypeEnum.DESCRIPTION) || false}
                        />
                        <label htmlFor='description' className={cx('labelCheckbox')}>
                          {t('tasks_btn_filter_description_label')}
                        </label>
                      </div>

                      <div className={cx('filterItemCheckbox')}>
                        <input
                          id='relates'
                          type='checkbox'
                          name='fields'
                          onChange={(e) => {
                            handleCheckboxChange(e, onChange, valueFilter);
                          }}
                          value={FieldTypeEnum.RELATES}
                          checked={valueFilter?.fields?.includes(FieldTypeEnum.RELATES) || false}
                        />
                        <label htmlFor='relates' className={cx('labelCheckbox')}>
                          {t('tasks_btn_filter_relate_label')}
                        </label>
                      </div>

                      <div className={cx('filterItemCheckbox')}>
                        <input
                          id='dueDate'
                          type='checkbox'
                          name='fields'
                          onChange={(e) => {
                            handleCheckboxChange(e, onChange, valueFilter);
                          }}
                          value={FieldTypeEnum.DUE_DATE}
                          checked={valueFilter?.fields?.includes(FieldTypeEnum.DUE_DATE) || false}
                        />
                        <label htmlFor='dueDate' className={cx('labelCheckbox')}>
                          {t('tasks_btn_filter_due_date_label')}
                        </label>
                      </div>
                    </div>
                  );
                }}
              </BaseFilter>

              {role !== AccountRoleCodesEnum.CAREGIVER && (
                <BaseButton
                  typeStyle={ButtonTypeEnum.PRIMARY}
                  iconLeft={icons.commonIconPlus}
                  text={t('task_page_button_add_task')}
                  onClick={handleShowTaskModal}
                />
              )}
            </ToolBar>
          </div>

          <div className={cx('tableWrap')}>
            <BaseTable
              columns={columnsTask(
                t,
                role,
                handleShowEditTask,
                handleShowConfirmDeleteTask,
                handleViewAssigneeDetail,
                handleViewRelatesDetail
              )}
              dataSource={listTaskData ?? []}
              onClickRow={handleTaskRowClick}
            />
          </div>

          <div className={cx('paginationTable')}>
            <BasePagination
              defaultCurrentPage={paginationTask?.page}
              totalItems={paginationTask?.totalItems}
              totalPages={paginationTask?.totalPages}
              onChange={handleTaskPaginationChange}
            />
          </div>
        </div>
      </div>

      <div className={cx('tableDashboard')}>
        <div className={cx('tableToolbar')}>
          <ToolBar fontSize={20} title={t('dashboard_statistic_table_title')}>
            <InputSearch
              placeholder={t('common_placeholder_search_by_name')}
              height={36}
              onChange={handleChangeCustomerSearch}
            />
          </ToolBar>
        </div>

        <div className={cx('tableStatistic')}>
          <BaseTable
            columns={columns(t, handleEdit, handleChangeStatus, handleViewDetails, handleSendMessage, role)}
            dataSource={clientList ?? []}
            onClickRow={handleCustomerRowClick}
          />
        </div>
        <div className={cx('paginationTable')}>
          <BasePagination
            onChange={handlePaginationCustomerChange}
            defaultCurrentPage={paginationCustomer?.page}
            totalItems={paginationCustomer?.totalItems}
            totalPages={paginationCustomer?.totalPages}
          />
        </div>
      </div>

      {isShowUpdateClientModal && (
        <CrmUpdateClientModal
          clientId={clientId}
          onClose={handleEdit}
          isOpen={isShowUpdateClientModal}
          type={CRMEnum.CLIENT}
        />
      )}

      {isShowTaskModal && (
        <FormTaskModal taskId={idTask} isOpen={isShowTaskModal} type='TASK' onClose={handleShowTaskModal} />
      )}

      <BaseDrawerModal isOpen={isShowAssigneeDetail} onClose={handleCloseAssigneeDetail}>
        <EmployeeDetailModalItem accountId={assigneeSelected!!} onEditInfo={handleEditInfo} />
      </BaseDrawerModal>

      {isShowConfirmDeleteTask && (
        <ConfirmModal
          title={t('common_confirm_delete_title', {
            name: t('common_text_this_task'),
          })}
          titleAction={t('common_delete_label')}
          onCancel={handleCloseConfirmDeleteTask}
          onAction={handleDeleteTask}
        />
      )}

      {isShowUnderDevelopment && <ModalUnderDevelopment onClose={handleCloseUnderDevelopment} />}
    </div>
  );
};

export default Home;
