import { createAsyncThunk } from '@reduxjs/toolkit';
import shiftScheduleApi from '~/apis/crm/shiftSchedule';
import scheduleApi from '~/apis/schedule';
import {
  CREATE_SHIFT_SCHEDULE,
  GET_LIST_SCHEDULE,
  DELETE_SCHEDULE,
  GET_LIST_SHIFT_SCHEDULE,
  GET_DETAIL_SCHEDULE,
  UPDATE_SCHEDULE,
  GET_SCHEDULE_CLIENT,
  GET_OFFICE_SCHEDULE,
  GET_SCHEDULES_LIVE_VIEW,
  CREATE_SCHEDULE,
  GET_DETAIL_SHIFT,
  GET_LIST_NOTE_SCHEDULE,
  CREATE_SHIFT_NOTE_SCHEDULE,
  UPDATE_SHIFT_SCHEDULE,
  GET_SCHEDULE_CAREGIVER,
  GET_SHIFT_SCHEDULE_CAREGIVER,
} from '~/utils/constants/actionType';
import { IFormCreateSchedule, IQueryParamGetListSchedule } from '~/utils/interface/crm/shiftSchedule';
import {
  ICreateShiftNote,
  IFormSchedule,
  IFormUpdateSchedule,
  IGetSchedulesLiveViewParams,
  IParamGetOfficeSchedule,
  IParamsGetNotes,
  IParamsGetScheduleCaregiver,
  IParamsGetScheduleClient,
  IParamsGetShiftScheduleClient,
  IPayloadEditSchedule,
} from '~/utils/interface/schedule';

export const createSchedule = createAsyncThunk(
  CREATE_SCHEDULE,
  async (payload: IFormCreateSchedule, { rejectWithValue }) => {
    try {
      const res = await shiftScheduleApi.createSchedule(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListShiftSchedule = createAsyncThunk(
  GET_LIST_SHIFT_SCHEDULE,
  async (payload: IQueryParamGetListSchedule, { rejectWithValue }) => {
    try {
      const res = await shiftScheduleApi.getListShiftSchedule(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListSchedule = createAsyncThunk(
  GET_LIST_SCHEDULE,
  async (params: IQueryParamGetListSchedule, { rejectWithValue }) => {
    try {
      const res = await shiftScheduleApi.getListSchedule(params);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteSchedule = createAsyncThunk(DELETE_SCHEDULE, async (scheduleId: string, { rejectWithValue }) => {
  try {
    const res = await scheduleApi.deleteSchedule(scheduleId);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDetailSchedule = createAsyncThunk(
  GET_DETAIL_SCHEDULE,
  async (scheduleId: string, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.getDetailSchedule(scheduleId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateSchedule = createAsyncThunk(
  UPDATE_SCHEDULE,
  async (payload: IPayloadEditSchedule, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.updateSchedule(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getScheduleClient = createAsyncThunk(
  GET_SCHEDULE_CLIENT,
  async (payload: IParamsGetScheduleClient, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.getScheduleClient(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getOfficeSchedule = createAsyncThunk(
  GET_OFFICE_SCHEDULE,
  async (payload: IParamGetOfficeSchedule, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.getOfficeSchedule(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSchedulesLiveView = createAsyncThunk(
  GET_SCHEDULES_LIVE_VIEW,
  async (payload: IGetSchedulesLiveViewParams, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.getSchedulesLiveView(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createShiftSchedule = createAsyncThunk(
  CREATE_SHIFT_SCHEDULE,
  async (payload: IFormSchedule, { rejectWithValue }) => {
    try {
      const res = await shiftScheduleApi.createShiftSchedule(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateShiftSchedule = createAsyncThunk(
  UPDATE_SHIFT_SCHEDULE,
  async (payload: IFormUpdateSchedule, { rejectWithValue }) => {
    try {
      const res = await shiftScheduleApi.updateShiftSchedule(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDetailShift = createAsyncThunk(GET_DETAIL_SHIFT, async (shiftId: string, { rejectWithValue }) => {
  try {
    const res = await shiftScheduleApi.getDetailShift(shiftId);
    return res.data.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getListNoteSchedule = createAsyncThunk(
  GET_LIST_NOTE_SCHEDULE,
  async (payload: IParamsGetNotes, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.getListNoteSchedule(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createShiftNoteSchedule = createAsyncThunk(
  CREATE_SHIFT_NOTE_SCHEDULE,
  async (payload: ICreateShiftNote, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.createShiftNoteSchedule(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getScheduleCaregiver = createAsyncThunk(
  GET_SCHEDULE_CAREGIVER,
  async (payload: IParamsGetScheduleCaregiver, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.getScheduleCaregiver(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getShiftScheduleClient = createAsyncThunk(
  GET_SHIFT_SCHEDULE_CAREGIVER,
  async (payload: IParamsGetShiftScheduleClient, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.getShiftScheduleClient(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
