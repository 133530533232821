import { createAsyncThunk } from '@reduxjs/toolkit';
import rateApi from '~/apis/rates';
import { GET_LIST_PAYROLL, CREATE_RATE, GET_LIST_RATE, UPDATE_PAYROLL_RATES } from '~/utils/constants/actionType';
import { IBodyPayrollRate, IFormRate, IListQueryParamsRate, IListQueryPayroll } from '~/utils/interface/rate';

export const getListRate = createAsyncThunk(
  GET_LIST_RATE,
  async (payload: IListQueryParamsRate, { rejectWithValue }) => {
    try {
      const res = await rateApi.getListRate(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListPayroll = createAsyncThunk(
  GET_LIST_PAYROLL,
  async (payload: IListQueryPayroll, { rejectWithValue }) => {
    try {
      const res = await rateApi.getListPayroll(payload);
      return { data: res.data, userType: payload.userType, rateType: payload.rateType };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createRate = createAsyncThunk(CREATE_RATE, async (payload: IFormRate, { rejectWithValue }) => {
  try {
    const res = await rateApi.createRate(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updatePayrollRate = createAsyncThunk(
  UPDATE_PAYROLL_RATES,
  async (payload: IBodyPayrollRate, { rejectWithValue }) => {
    try {
      const res = await rateApi.updatePayrollRate(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
