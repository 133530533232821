// Libs
import classNames from 'classnames/bind';
import { useParams } from 'react-router-dom';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Event } from 'react-big-calendar';
import moment from 'moment';
import { Tooltip } from 'react-tooltip';
// Components, Layouts, Pages
import { NewEventPopup, NewMySchedule, NewScheduleAssignee, ReactPortal } from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { TCalendarViewMode } from '~/utils/type/schedule';
import { DEFAULT_VIEW_MODE } from '~/utils/constants/common';
import { DateFormatEnum } from '~/utils/enum';
import { IParamsGetShiftScheduleClient, IScheduleClient } from '~/utils/interface/schedule';
import { getShiftScheduleClient } from '~/thunks/schedule/scheduleThunk';
import { handleEventStatusColor } from '~/utils/helpers/common';
import { getUserName } from '~/utils/helper';
import { prepareMonthEventsDataClient, prepareTimeEventsData } from './helper';
import { scheduleActions } from '~/thunks/schedule/scheduleSlice';
import { RootState } from '~/redux/store';
// Styles, images, icons
import styles from './ClientScheduleTab.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const EventScheduleClient = (props: { event: IScheduleClient; view: TCalendarViewMode }) => {
  const { event, view } = props;
  const { id, status, caregiver, startTime, endTime } = event;
  const formattedStartTime = moment(startTime, 'HH:mm:ss').format('h:mm A');
  const formattedEndTime = moment(endTime, 'HH:mm:ss').format('h:mm A');

  return (
    <div
      id='eventContainer'
      className={cx('eventContainer', view === 'month' && 'monthEvent')}
      style={{
        borderWidth: 1,
        backgroundColor: handleEventStatusColor(status),
      }}
    >
      <div className={cx('eventWrap')}>
        <div className={cx('assignees')}>
          {caregiver && (
            <NewScheduleAssignee
              data={[caregiver]}
              eventColor={handleEventStatusColor(status)}
              width={32}
              height={32}
            />
          )}
        </div>

        <div className={cx('firstLastName')}>
          <div className={cx('eventName')} data-tooltip-id={`caregiver-${id}`} data-tooltip-place='left'>
            {getUserName(caregiver?.firstName, caregiver?.lastName)}
          </div>

          <div className={cx('eventTime')}>{startTime && endTime && `${formattedStartTime} - ${formattedEndTime}`}</div>
        </div>
      </div>

      <ReactPortal wrapperId='clientTooltip'>
        <Tooltip id={`caregiver-${id}`} className={'tooltipWrap'}>
          <div className='tooltip'>
            {caregiver && <span className='tooltipText'>{getUserName(caregiver.firstName, caregiver.lastName)}</span>}
          </div>
        </Tooltip>
      </ReactPortal>
    </div>
  );
};

const ClientScheduleTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const { clientId } = useParams();
  const { isRefreshShiftSchedules } = useAppSelector((state: RootState) => state.scheduleState);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [scheduleEvents, setScheduleEvents] = useState<IScheduleClient[]>([]);
  const [calendarViewMode, setCalendarViewMode] = useState<TCalendarViewMode>(DEFAULT_VIEW_MODE);
  const [currentTime, setCurrentTime] = useState<string>(moment().format(DateFormatEnum.YYYY_MM_DD));
  const [isShowEvent, setIsShowEvent] = useState<boolean>(false);
  const [dataEvent, setDataEvent] = useState<IScheduleClient>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListScheduleClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime, calendarViewMode]);

  useEffect(() => {
    if (!isRefreshShiftSchedules) return;

    handleGetListScheduleClient();
    dispatch(scheduleActions.setRefreshShiftSchedules(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshShiftSchedules]);

  const events: Event[] = useMemo(() => {
    switch (calendarViewMode) {
      case 'month':
        const preparedEvents = prepareMonthEventsDataClient(scheduleEvents);

        return preparedEvents;

      case 'week':
      case 'day':
        const preparedEventWeekDay = prepareTimeEventsData(scheduleEvents);

        return preparedEventWeekDay;
    }
  }, [scheduleEvents, calendarViewMode]);

  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListScheduleClient = () => {
    if (!clientId) return;
    loading?.show();

    const startDate = moment(currentTime).startOf(calendarViewMode).format(DateFormatEnum.YYYY_MM_DD);
    const endDate = moment(currentTime).endOf(calendarViewMode).format(DateFormatEnum.YYYY_MM_DD);

    const params: IParamsGetShiftScheduleClient = {
      clientId: clientId,
      endDate: endDate,
      startDate: startDate,
    };

    dispatch(getShiftScheduleClient(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        setScheduleEvents(res.data);
      })
      .catch((_err) => {})
      .finally(() => loading?.hide());
  };

  const handleTimeChange = (timeValue: string) => {
    if (!timeValue) return;
    setCurrentTime(timeValue);
  };

  const handleCalendarViewMode = (view: TCalendarViewMode) => {
    if (!view) return;
    setCalendarViewMode(view);
    setCurrentTime(moment().format(DateFormatEnum.YYYY_MM_DD));
  };

  const handleSelectEvent = (event: IScheduleClient) => {
    if (!event) return;

    setIsShowEvent(true);
    setDataEvent(event);
  };

  const handleShowEvent = () => {
    setIsShowEvent(!isShowEvent);
  };
  //#endregion Handle Function

  return (
    <div id='clientScheduleTab' className={cx('clientScheduleTab')}>
      <NewMySchedule<IScheduleClient>
        events={events || []}
        date={currentTime}
        onTimeChange={handleTimeChange}
        childrenEvent={(event) => <EventScheduleClient event={event} view={calendarViewMode} />}
        onSelectEvent={handleSelectEvent}
        onCalendarViewMode={handleCalendarViewMode}
      />

      <NewEventPopup isOpen={isShowEvent} onClose={handleShowEvent} data={dataEvent} />
    </div>
  );
};

export default ClientScheduleTab;
