// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useContext, useMemo, ChangeEvent } from 'react';
import { TFunction } from 'i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

// Components, Layouts, Pages
import {
  BaseButton,
  InputSearch,
  ModalUnderDevelopment,
  ToolBar,
  BaseTable,
  ThreeDotTableOptions,
  BasePagination,
  ConfirmModal,
  FormTaskModal,
  BaseDrawerModal,
  EmployeeDetailModalItem,
  BaseFilter,
} from '~/components';

// Context
import { LoadingData } from '~/context';

// Others
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_FILTER_TASK,
  DEFAULT_LIMIT_PAGE,
  DEFAULT_NUMBER_ONE,
  EMPTY_STRING,
} from '~/utils/constants/common';
import {
  AccountRoleCodesEnum,
  ButtonTypeEnum,
  TimeFormatEnum,
  TranslationEnum,
  CRMEnum,
  FieldTypeEnum,
  FilterParamTaskEnum,
} from '~/utils/enum';
import { ColumnTableType, IFilterValueChange, IPaginationResponse, IQueryParams } from '~/utils/interface/common';
import { IDefaultFilterTask, IQueryListTask, ITask, ITaskDetailRelates } from '~/utils/interface/task';
import { convertDateToFormatTime, convertEnumToString } from '~/utils/helper';
import { deleteTask, getListTask } from '~/thunks/task/taskThunk';
import useDebounce from '~/utils/customHook';
import { selectIsRefreshTaskList, taskActions } from '~/thunks/task/taskSlice';
import {
  adminRoute,
  adminRouteAbsolute,
  caregiverRouteAbsolute,
  staffRouteAbsolute,
  superAdminRoute,
  superAdminRouteAbsolute,
} from '~/utils/constants/route';

// Styles, images, icons
import styles from './Task.module.scss';
import { icons } from '~/assets';

type Props = {
  role: string;
};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  role: string,
  handleEdit: (taskId: string) => void,
  handleDelete: (record: ITask) => void,
  onViewAssigneeDetail: (id: string) => void,
  onViewRelatesDetail: (record: ITaskDetailRelates) => void
): ColumnTableType<ITask>[] => {
  return [
    {
      key: 'dueDate',
      dataIndex: 'dueDate',
      title: t('task_page_table_due_date_label'),
      render(value, record) {
        return (
          <>{record?.dueDate ? convertDateToFormatTime(record?.dueDate, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}</>
        );
      },
      width: '10%',
    },
    {
      key: 'assignee',
      title: t('task_page_table_assignee_label'),
      render: (value, record) => {
        return (
          record?.assignees && (
            <div>
              {record?.assignees?.map((assignee, index) => (
                <span
                  key={assignee?.id}
                  onClick={(e) => {
                    e.stopPropagation();
                    onViewAssigneeDetail(String(assignee?.id));
                  }}
                  className={cx('tableLink')}
                >
                  {assignee?.firstName}
                  {index < record?.assignees?.length - DEFAULT_NUMBER_ONE && ', '}
                </span>
              ))}
            </div>
          )
        );
      },
      width: '20%',
    },
    {
      key: 'description',
      title: t('task_page_table_description_label'),
      render: (value, record, index) => {
        return (
          <div className={cx('textTable')}>
            {record?.description ? <span>{record?.description}</span> : EMPTY_STRING}
          </div>
        );
      },
      width: '40%',
    },
    {
      key: 'relates',
      title: t('task_modal_task_related_to_label'),
      render: (value, record, index) => {
        return (
          <div>
            {record?.relates?.map((relate, index) => (
              <span
                key={relate?.id}
                className={cx('tableLink')}
                onClick={(e) => {
                  e.stopPropagation();
                  onViewRelatesDetail(relate);
                }}
              >
                {relate?.firstName}
                {index < record?.relates?.length - DEFAULT_NUMBER_ONE && ', '}
              </span>
            ))}
          </div>
        );
      },
    },
    {
      key: 'action',
      title: t('common_text_action'),
      render(value, record, index) {
        return (
          <ThreeDotTableOptions
            data={record}
            permissions={{
              isEdit:
                role === AccountRoleCodesEnum.ADMIN ||
                role === AccountRoleCodesEnum.EMPLOYEE ||
                role === AccountRoleCodesEnum.SUPER_ADMIN,
              isDelete: role === AccountRoleCodesEnum.ADMIN || role === AccountRoleCodesEnum.SUPER_ADMIN,
              isView: false,
            }}
            // onView={() => handleViewDetails(record.id)}
            onEdit={() => handleEdit(record?.id)}
            onDelete={() => handleDelete(record)}
          />
        );
      },
      width: 50,
    },
  ];
};

const Task = (props: Props) => {
  //#region Destructuring Props
  const { role } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion Declare Hook

  //#region Selector
  const isRefreshTaskList = useAppSelector(selectIsRefreshTaskList);
  //#endregion Selector

  //#region Declare State
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchKey, setSearchKey] = useState<string>(EMPTY_STRING);
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const textSearchParams = useMemo<string>(() => String(params?.textSearch || EMPTY_STRING), [params?.textSearch]);
  const [paramObject, setParamObject] = useState<IQueryParams>({
    page: pageSelected,
    limit: DEFAULT_LIMIT_PAGE,
  });
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [hasInteracted, setHasInteracted] = useState<boolean>(false);
  const renderSearchKey = useMemo(() => {
    if (!hasInteracted && textSearchParams) {
      return textSearchParams;
    }

    if (hasInteracted && searchKey === EMPTY_STRING) {
      return EMPTY_STRING;
    }

    if (searchKey) {
      return searchKey;
    }

    return EMPTY_STRING;
  }, [searchKey, textSearchParams, hasInteracted]);
  const debouncedSearchKey = useDebounce<string>(renderSearchKey.trim() || EMPTY_STRING, DEFAULT_DELAY_TIME);
  const [isShowTaskModal, setIsShowTaskModal] = useState<boolean>(false);
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const [listTaskData, setListTaskData] = useState<ITask[]>([]);
  const [idTask, setIdTask] = useState<string>(EMPTY_STRING);
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false);
  const [isShowTaskDetail, setIsShowTaskDetail] = useState<boolean>(false);
  const [assigneeSelected, setAssigneeSelected] = useState<string>();
  const [isShowAssigneeDetail, setIsShowAssigneeDetail] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<IQueryListTask>(DEFAULT_FILTER_TASK);
  const fieldsText = useMemo(() => {
    return filterParams.fields
      ? filterParams.fields.map((field) => convertEnumToString(field)).join(', ')
      : EMPTY_STRING;
  }, [filterParams.fields]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const newParamObject: IQueryParams = {
      ...paramObject,
      ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
    };

    handleGetListTask(newParamObject);
    const newParam = debouncedSearchKey
      ? {
          ...params,
          page: paramObject.page!.toString(),
          limit: DEFAULT_LIMIT_PAGE.toString(),
          // textSearch: debouncedSearchKey,
        }
      : { page: paramObject.page!.toString(), limit: DEFAULT_LIMIT_PAGE.toString() };

    setSearchParams(newParam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramObject]);

  useEffect(() => {
    if (!pagination) return;
    setParamObject({
      ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_PAGE,
      ...(filterParams ? { ...filterParams } : {}),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (!isRefreshTaskList) {
      return;
    }

    handleGetListTask(paramObject);
    dispatch(taskActions.setRefreshList(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshTaskList]);

  useEffect(() => {
    if (!location?.state || !location?.state?.taskId) return;

    handleEdit(location?.state?.taskId);
    navigate(location.pathname, {});

    return () => {
      if (location?.state?.taskId) {
        navigate(location.pathname, {});
      }
    };
  }, [location.state]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListTask = (payload: IQueryParams) => {
    loading?.show();

    dispatch(getListTask(payload))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        setListTaskData(res.data.responses);
        setPagination(res.data.pagination);
      })
      .catch((error) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handlePaginationChange = (page: number) => {
    const newParamObject: IQueryParams = { ...paramObject, page };
    setParamObject(newParamObject);
    setSearchParams({ ...params, page: page.toString(), limit: `${DEFAULT_LIMIT_PAGE}` });
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
    setHasInteracted(true);
  };

  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleSortBy = () => {
    // @TODO: Handle Sort By
    setIsShowModalUnderDevelopment(true);
  };

  const handleFilter = () => {
    // @TODO: Handle Filter
    setIsShowModalUnderDevelopment(true);
  };

  const handleShowTaskModal = () => {
    setIdTask(EMPTY_STRING);
    setIsShowTaskModal(!isShowTaskModal);
  };

  const handleEdit = (taskId: string) => {
    setIdTask(taskId);
    setIsShowTaskModal(!isShowTaskModal);
  };

  const handleDelete = (item: ITask) => {
    if (item) {
      setIdTask(item.id);
    }

    setIsShowConfirmDelete(true);
  };

  const handleCloseModalConfirmDelete = () => {
    setIsShowConfirmDelete(false);
  };

  const handleDeleteTask = () => {
    loading?.show();

    if (!idTask) return;
    dispatch(deleteTask(idTask))
      .unwrap()
      .then((res) => {
        dispatch(taskActions.setRefreshList(true));
      })
      .catch((err) => {})
      .finally(() => {
        handleCloseModalConfirmDelete();
        loading?.hide();
      });
  };

  const handleViewAssigneeDetail = (id: string) => {
    if (!id) return;

    setIsShowAssigneeDetail(true);
    setAssigneeSelected(id);
  };

  const handleCloseAssigneeDetail = () => {
    setIsShowAssigneeDetail(false);
    setAssigneeSelected(EMPTY_STRING);
  };

  const handleViewRelatesDetail = (data: ITaskDetailRelates) => {
    if (!data || !role) return;

    if (data?.userType === CRMEnum.CLIENT) {
      switch (role) {
        case AccountRoleCodesEnum.SUPER_ADMIN:
          navigate(`${superAdminRouteAbsolute.crmClient}/${data?.id}`);
          return;
        case AccountRoleCodesEnum.ADMIN:
          navigate(`${adminRouteAbsolute.crmClient}/${data?.id}`);
          return;
        case AccountRoleCodesEnum.EMPLOYEE:
          navigate(`${staffRouteAbsolute.crmClient}/${data?.id}`);
          return;

        default:
          return;
      }
    }

    if (data?.userType === CRMEnum.PROSPECT) {
      switch (role) {
        case AccountRoleCodesEnum.SUPER_ADMIN:
          navigate(`${superAdminRouteAbsolute.crmProspect}/${data?.id}`);
          return;
        case AccountRoleCodesEnum.ADMIN:
          navigate(`${adminRouteAbsolute.crmProspect}/${data?.id}`);
          return;

        case AccountRoleCodesEnum.EMPLOYEE:
          navigate(`${staffRouteAbsolute.crmProspect}/${data?.id}`);
          return;

        default:
          return;
      }
    }

    if (data?.userType === CRMEnum.REFERRAL) {
      switch (role) {
        case AccountRoleCodesEnum.SUPER_ADMIN:
          navigate(`${superAdminRouteAbsolute.crmReferralSource}/${data?.id}`);
          return;
        case AccountRoleCodesEnum.ADMIN:
          navigate(`${adminRouteAbsolute.crmReferralSource}/${data?.id}`);
          return;

        case AccountRoleCodesEnum.EMPLOYEE:
          navigate(`${staffRouteAbsolute.crmReferralSource}/${data?.id}`);
          return;

        default:
          return;
      }
    }

    if (data?.userType === CRMEnum.USER) {
      setIsShowAssigneeDetail(true);
      setAssigneeSelected(data.id);
    }
  };

  const handleCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    onChange: (data: IFilterValueChange<IDefaultFilterTask>) => void,
    valueFilter: IDefaultFilterTask | undefined
  ) => {
    const { name, value, checked } = event.target;
    const newFields = {
      ...filterParams,
      fields: checked
        ? [...(valueFilter?.fields ?? []), value]
        : (valueFilter?.fields ?? []).filter((field) => field !== value),
    };

    if (name === FilterParamTaskEnum.FIELDS) {
      onChange({ name: FilterParamTaskEnum.FIELDS, value: newFields.fields });
    }
  };

  const handleFilterChange = (appliedFilter: IDefaultFilterTask | undefined) => {
    if (!appliedFilter) {
      setFilterParams({});
      return;
    }

    setFilterParams({
      ...appliedFilter,
    });

    if (debouncedSearchKey)
      setParamObject({
        ...paramObject,
        ...appliedFilter,
      });
  };

  const handleEditInfo = (id: string) => {
    if (!id) return;
    setIsShowAssigneeDetail(false);

    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRoute.base}${superAdminRoute.employees}${superAdminRoute.editEmployees}/${id}`);
        break;

      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRoute.base}${adminRoute.employees}${adminRoute.editEmployees}/${id}`);
        break;
    }
  };

  const handleTableRowClick = (data: ITask) => {
    if (!data) return;

    handleEdit(data?.id);
  };
  //#endregion Handle Function

  return (
    <div id='taskPage' className={cx('container')}>
      <ToolBar title={t('task_page_title')}>
        <InputSearch
          placeholder={t('tasks_text_search_placeholder', {
            params: fieldsText,
          })}
          onChange={handleChangeSearch}
          width={204}
          // value={textSearchParams}
        />
        <BaseFilter<IDefaultFilterTask>
          onApply={handleFilterChange}
          defaultValue={DEFAULT_FILTER_TASK}
          valueFilter={filterParams}
        >
          {({ valueFilter, onChange }) => {
            return (
              <div className={cx('filterWrap')}>
                <div className={cx('filterItemCheckbox')}>
                  <input
                    id='description'
                    type='checkbox'
                    name='fields'
                    onChange={(e) => {
                      handleCheckboxChange(e, onChange, valueFilter);
                    }}
                    value={FieldTypeEnum.DESCRIPTION}
                    checked={valueFilter?.fields?.includes(FieldTypeEnum.DESCRIPTION) || false}
                  />
                  <label htmlFor='description' className={cx('labelCheckbox')}>
                    {t('tasks_btn_filter_description_label')}
                  </label>
                </div>

                <div className={cx('filterItemCheckbox')}>
                  <input
                    id='relates'
                    type='checkbox'
                    name='fields'
                    onChange={(e) => {
                      handleCheckboxChange(e, onChange, valueFilter);
                    }}
                    value={FieldTypeEnum.RELATES}
                    checked={valueFilter?.fields?.includes(FieldTypeEnum.RELATES) || false}
                  />
                  <label htmlFor='relates' className={cx('labelCheckbox')}>
                    {t('tasks_btn_filter_relate_label')}
                  </label>
                </div>

                <div className={cx('filterItemCheckbox')}>
                  <input
                    id='dueDate'
                    type='checkbox'
                    name='fields'
                    onChange={(e) => {
                      handleCheckboxChange(e, onChange, valueFilter);
                    }}
                    value={FieldTypeEnum.DUE_DATE}
                    checked={valueFilter?.fields?.includes(FieldTypeEnum.DUE_DATE) || false}
                  />
                  <label htmlFor='dueDate' className={cx('labelCheckbox')}>
                    {t('tasks_btn_filter_due_date_label')}
                  </label>
                </div>
              </div>
            );
          }}
        </BaseFilter>
        {role !== AccountRoleCodesEnum.CAREGIVER && (
          <BaseButton
            typeStyle={ButtonTypeEnum.PRIMARY}
            iconLeft={icons.commonIconPlus}
            text={t('task_page_button_add_task')}
            onClick={handleShowTaskModal}
          />
        )}
      </ToolBar>

      <div className={cx('content')}>
        <div className={cx('tableWrap')}>
          <BaseTable
            columns={columns(t, role, handleEdit, handleDelete, handleViewAssigneeDetail, handleViewRelatesDetail)}
            dataSource={listTaskData ?? []}
            onClickRow={handleTableRowClick}
          />
        </div>
        <div className={cx('paginationTable')}>
          <BasePagination
            defaultCurrentPage={pageSelected}
            totalItems={pagination?.totalItems}
            totalPages={pagination?.totalPages}
            onChange={handlePaginationChange}
          />
        </div>
      </div>

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}

      {isShowTaskModal && <FormTaskModal taskId={idTask} isOpen={isShowTaskModal} onClose={handleShowTaskModal} />}

      {isShowConfirmDelete && (
        <ConfirmModal
          title={t('common_confirm_delete_title', {
            name: t('common_text_this_task'),
          })}
          titleAction={t('common_delete_label')}
          onCancel={handleCloseModalConfirmDelete}
          onAction={handleDeleteTask}
        />
      )}

      <BaseDrawerModal isOpen={isShowAssigneeDetail} onClose={handleCloseAssigneeDetail}>
        <EmployeeDetailModalItem accountId={assigneeSelected!!} onEditInfo={handleEditInfo} />
      </BaseDrawerModal>

      {/*<BaseDrawerModal isOpen={isShowTaskDetail} onClose={handleViewDetails}>*/}
      {/*  <TaskDetail taskId={idTask} />*/}
      {/*</BaseDrawerModal>*/}
    </div>
  );
};

export default Task;
