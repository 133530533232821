export const ZINC_1A191B = '#1A191B';
export const GRAY_D5D5D5 = '#D5D5D5';
export const GRAY_EBF4F2 = '#EBF4F2';
export const RED_FF0000 = '#FF0000';
export const TEAL_24604D = '#24604D';
export const WHITE = '#FFFFFF';
export const FINN900 = '#6C2364';
export const GREEN_PEA700 = '#24604D';
export const GRAY_NURSE100 = '#DFE8E5';
export const EDWARD300 = '#A3B4AF';
export const SKEPTIC = '#C4E9DD';
export const GRAY_E9E9E9 = '#E9E9E9';
export const BLUE_SMOKE = '#69847C';
export const MINERAL_GREEN = '#49685F';
export const MALACHITE = '#04ca3c';
export const TEAL950 = '#043829';
export const NEUTRAL_RED_800 = '#940E0E';
export const NEUTRAL_VIOLET_900 = '#510E94';
export const NEUTRAL_CYAN_700 = '#0E7C94';
export const NEUTRAL_LIME_700 = '#39940E';
export const NEUTRAL_EMERALD_600 = '#0E946D';
export const NEUTRAL400 = '#9C9C9F';
export const CORDUROY_500 = '#64736e';
export const PRIMARY_SIROCCO = '#687B76';
export const BLUE = '#73B0D7';
export const ORANGE = '#FFA500';

export const statusColor = {
  TEAL_24604D: '#24604D',
  AZURE_RADIANCE_0682e3: '#0682e3',
  FINN900: '#6C2364',
  PURPLE_HEART_682ee4: '#682ee4',
  RED_FF0000: '#FF0000',
  YELLOW_500: '#C3C800',
  GREEN_800: '#1E731F',
  ROSE_400: '#FF6A7E',
  ROSE_600: '#DC2F40',
  INDIGO_300: '#AECAFC',
  INDIGO_400: '#8995E4',
  ORANGE_300: '#F6AE6F',
  ORANGE_400: '#DB8246',
  RED_800: '#932B2B',
  EMERALD_300: '#79D2B9',
  ROSE_500: '#D27783',
  LIME_100: '#EBEDC5',
  SKY_300: '#84CCEB',
  EMERALD_50: '#EEF9F5',
  BLUE_600: '#2563EB',
  SLATE_300: '#CBD5E1',
  NEUTRAL_INDIGO_400: '#AFA6D3',
  NEUTRAL_200: '#E1E3E3',
};
