// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Views } from 'react-big-calendar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { ReactCalendarItemRendererProps } from 'react-calendar-timeline';
import { RootState } from '~/redux/store';
// Components, Layouts, Pages
import {
  BaseButton,
  ModalUnderDevelopment,
  ToolBar,
  SelectStatus,
  FormShiftScheduleModal,
  EventDailyTimeline,
  NewFullSchedule,
  NewEventPopup,
} from '~/components';
// Others
import {
  AccountRoleCodesEnum,
  ButtonTypeEnum,
  DateFormatEnum,
  PageViewScheduleEnum,
  SchedulesLiveViewTypeEnum,
  StatusEnum,
  StorageEnum,
} from '~/utils/enum';
import { TCalendarViewMode, TPageViewSchedule } from '~/utils/type/schedule';
import { optionsStatusShiftSchedule } from '~/mockData';
import { adminRouteAbsolute, staffRouteAbsolute, superAdminRouteAbsolute } from '~/utils/constants/route';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT_MAX_ITEM,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { convertTimelineSchedule, prepareTimelineEventsData } from '../scheduleModules/helper';
import { LoadingData } from '~/context';
import {
  IGetSchedulesLiveViewParams,
  IScheduleTimelineEvent,
  ITimelineEvent,
  ITimelinePreparedData,
} from '~/utils/interface/schedule';
import { getSchedulesLiveView } from '~/thunks/schedule/scheduleThunk';
import { IBaseOption } from '~/utils/interface/common';
// Styles, images, icons
import styles from './LiveViewSchedule.module.scss';
import { icons } from '~/assets';

type Props = {
  modeView: TCalendarViewMode;
  pageView: TPageViewSchedule;
};

const cx = classNames.bind(styles);

const LiveViewSchedule = (props: Props) => {
  //#region Destructuring Props
  const { modeView, pageView } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const role = localStorage.getItem(StorageEnum.ROLE);
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshShiftSchedules } = useAppSelector((state: RootState) => state.scheduleState);
  //#endregion Selector

  //#region Declare State
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [isOpenAddScheduleModal, setIsAddScheduleModal] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<string>();
  const [timelineEvent, setTimelineEvent] = useState<ITimelinePreparedData>();
  const [isShowEvent, setIsShowEvent] = useState<boolean>(false);
  const [dataEvent, setDataEvent] = useState<IScheduleTimelineEvent>();
  const [typeStatusSelect, setTypeStatusSelect] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!currentTime) return;
    const { status, ...restParams } = params;

    const startOfWeek = moment(currentTime).startOf('week').format(DateFormatEnum.YYYY_MM_DD);
    const endOfWeek = moment(currentTime).endOf('week').format(DateFormatEnum.YYYY_MM_DD);

    const payload: IGetSchedulesLiveViewParams = {
      ...restParams,
      startDate: modeView === Views.WEEK ? startOfWeek : currentTime,
      endDate: modeView === Views.WEEK ? endOfWeek : currentTime,
      type:
        pageView === PageViewScheduleEnum.CLIENTS
          ? SchedulesLiveViewTypeEnum.CLIENT
          : SchedulesLiveViewTypeEnum.CAREGIVER,
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
      ...(params?.status === StatusEnum.SHOW_ALL ? {} : { status: params?.status }),
    };

    handleGetScheduleLiveView(payload);
  }, [currentTime, params]);

  useEffect(() => {
    if (!currentTime) return;

    const { status, ...restParams } = params;

    const startOfWeek = moment(currentTime).startOf('week').format(DateFormatEnum.YYYY_MM_DD);
    const endOfWeek = moment(currentTime).endOf('week').format(DateFormatEnum.YYYY_MM_DD);

    const payload: IGetSchedulesLiveViewParams = {
      ...restParams,
      startDate: modeView === Views.WEEK ? startOfWeek : currentTime,
      endDate: modeView === Views.WEEK ? endOfWeek : currentTime,
      type:
        pageView === PageViewScheduleEnum.CLIENTS
          ? SchedulesLiveViewTypeEnum.CLIENT
          : SchedulesLiveViewTypeEnum.CAREGIVER,
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
      ...(params?.status === StatusEnum.SHOW_ALL ? {} : { status: params?.status }),
    };

    if (isRefreshShiftSchedules) {
      handleGetScheduleLiveView(payload);
    }
  }, [isRefreshShiftSchedules]);

  useEffect(() => {
    const typeStatus: string = String(optionsStatusShiftSchedule[DEFAULT_NUMBER_ZERO].value);

    setTypeStatusSelect(params.status || typeStatus);
  }, [params?.status]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetScheduleLiveView = (payload: IGetSchedulesLiveViewParams) => {
    loading?.show();

    dispatch(getSchedulesLiveView(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data.responses) return;

        const dataTimeLine = convertTimelineSchedule(res?.data.responses, pageView);
        const prepareTimelineEvent = prepareTimelineEventsData(dataTimeLine, modeView);
        setTimelineEvent(prepareTimelineEvent);
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };

  const handleShowEvent = (showEvent: boolean, event?: IScheduleTimelineEvent) => {
    if (event) setDataEvent(event as IScheduleTimelineEvent);
    setIsShowEvent(showEvent);
  };

  const handleSortBy = () => {
    // @TODO: Handle Sort By
    setIsShowModalUnderDevelopment(true);
  };

  const handleShowModalAddSchedule = () => {
    setIsAddScheduleModal(!isOpenAddScheduleModal);
  };

  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleNavigate = () => {
    if (
      (role === AccountRoleCodesEnum.ADMIN ||
        role === AccountRoleCodesEnum.SUPER_ADMIN ||
        role === AccountRoleCodesEnum.EMPLOYEE) &&
      (pageView === PageViewScheduleEnum.CLIENTS || pageView === PageViewScheduleEnum.CAREGIVER)
    ) {
      const routeMap = {
        [AccountRoleCodesEnum.SUPER_ADMIN]: {
          [PageViewScheduleEnum.CLIENTS]: superAdminRouteAbsolute.weeklyClients,
          [PageViewScheduleEnum.CAREGIVER]: superAdminRouteAbsolute.weeklyCaregivers,
        },
        [AccountRoleCodesEnum.ADMIN]: {
          [PageViewScheduleEnum.CLIENTS]: adminRouteAbsolute.weeklyClients,
          [PageViewScheduleEnum.CAREGIVER]: adminRouteAbsolute.weeklyCaregivers,
        },
        [AccountRoleCodesEnum.EMPLOYEE]: {
          [PageViewScheduleEnum.CLIENTS]: staffRouteAbsolute.weeklyClients,
          [PageViewScheduleEnum.CAREGIVER]: staffRouteAbsolute.weeklyCaregivers,
        },
      };

      const route = routeMap[role]?.[pageView];

      if (route) {
        navigate(route);
      }
    }
  };

  const handleChangeTitle = (modeView: string, pageView: string) => {
    const titleMapping: Record<string, Record<string, string>> = {
      [Views.DAY]: {
        [PageViewScheduleEnum.CLIENTS]: t('tab_daily_clients_schedule'),
        [PageViewScheduleEnum.CAREGIVER]: t('tab_daily_caregivers_schedule'),
      },
      [Views.WEEK]: {
        [PageViewScheduleEnum.CLIENTS]: t('tab_weekly_clients_schedule'),
        [PageViewScheduleEnum.CAREGIVER]: t('tab_weekly_caregivers'),
      },
    };

    return titleMapping[modeView]?.[pageView] || EMPTY_STRING;
  };

  const handleFilterStatus = (data: IBaseOption) => {
    const { status, ...restParams } = params;

    setSearchParams({
      ...restParams,
      ...(data?.value === StatusEnum.SHOW_ALL ? {} : { status: data?.value.toString() }),
    });
  };
  //#endregion Handle Function

  return (
    <div id='liveViewScheduleComponent' className={cx('container')}>
      <ToolBar title={handleChangeTitle(modeView, pageView)}>
        <SelectStatus
          options={optionsStatusShiftSchedule}
          placeholder={t('common_placeholder_select')}
          width={204}
          height={36}
          value={typeStatusSelect}
          onChange={handleFilterStatus}
        />
        {/* <BaseButton iconLeft={icons.commonIconSort} text={t('common_text_filter')} width={67} onClick={handleSortBy} /> */}
        <BaseButton
          typeStyle={ButtonTypeEnum.PRIMARY}
          iconLeft={icons.commonIconPlus}
          text={t('schedule_tab_button_add_schedule')}
          onClick={handleShowModalAddSchedule}
        />
      </ToolBar>

      <div className={cx('content')}>
        <NewFullSchedule
          view={modeView}
          events={timelineEvent}
          onTimeChange={setCurrentTime}
          timelineEventItem={(props: ReactCalendarItemRendererProps<ITimelineEvent>) => (
            <EventDailyTimeline
              eventData={props.item}
              viewType={modeView}
              onSelectEvent={(events) => handleShowEvent(true, events)}
              {...props}
            />
          )}
          rightHeaderComponent={
            <div>
              {modeView === Views.DAY && (
                <button className={cx('btnCalendarView')} onClick={handleNavigate}>
                  {t('crm_client_detail_schedule_btn_view_week')}
                </button>
              )}
            </div>
          }
        />
      </div>

      {isOpenAddScheduleModal && (
        <FormShiftScheduleModal isOpen={isOpenAddScheduleModal} onClose={handleShowModalAddSchedule} />
      )}

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}

      <NewEventPopup isOpen={isShowEvent} onClose={() => handleShowEvent(false)} data={dataEvent} />
    </div>
  );
};

export default LiveViewSchedule;
